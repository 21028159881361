

.rdc-modal {
	min-width: 30vw; // 400px;
	max-width: 860px;
	max-height: 100vh;
	padding: 0;
	border-radius: 12px;
	background-color: var(--rdc-white-color, white);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: var(--main-font-color, #173261);
	box-shadow: 0px 0px 35px 0px #B6CDE9CC, 0px 1px 4px 0px #1975BA40;
}

.rdc-modal.red {
	box-shadow: 0px 2px 25px 0px #FF314040, 0px 1px 6px 0px #FF314040;
}