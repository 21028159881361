

.rdcAlert {
	width: 100%;
	display: flex;
	align-items: center;
	gap: 20px;

	padding: 8px 20px;
	border-radius: 12px;

	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: -0.022em;
	text-align: left;
	
	color: var(--rdc-blue-text, #173261 );
	background: var(---rdc-red-light, #FEE2E2);
}