
.tree-info {

    &__header {
        display: flex;
        align-items: center;
        gap: 8px;

        & > button {
            display: none;
        }

        &:hover > button {
            display: flex;
        }
    }

    &__title {
        padding: 4px 0;
        margin-bottom: 0;
    }
    &__list {
        display: flex;
        flex-direction: column;
        border-radius: 12px;
    }

    &__item {
        display: flex;
        flex-direction: column;
        padding: 8px 24px 8px 24px;
        border-radius: 12px;

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            & > button {
                display: none;
            }
        }

        &:hover {
            background-color: #828F9C1F;
            div > button {
                display: flex;
            }
        }

        &__sub-title {
            padding: 5px 0;
            &-mrk {
                color: var( --rdc-gray-dark, #748398);
            }
        }

        &__value {
            line-height: 22px;
            & > p {
                padding: 5px 0;
                margin-bottom: 0;
            }
        }

    }

}