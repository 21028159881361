
@mixin css-arrow( $height: 12px, $color: white, $top: 16px ) {
	
	&:after {
		border: solid transparent;
		content: " ";
		display: block;
		height: 0;
		position: absolute;
		pointer-events: none;
		width: 0;
		right: calc( 100% - 5px);
	}
  
	&:after {
		border-color: rgba(255, 255, 255, 0);
		border-right-color: $color;
		top: $top;
		margin-top: -$height;
		border-width: $height;
	}
  
}

.btn {
	padding: 0;
}

.active {
	background-color: var( --rdc-blue-color, #fff ) !important;
	fill: var( --rdc-white-color, #fff ) !important;
}

.wrapper {
	position: relative;
}

.modal {
	position: absolute;
	top: 0;
	left: calc( 100% + 12px );
	z-index: 300;
	@include css-arrow();
	width: max-content;
	max-width: 45vw;
	background-color: var( --rdc-white-color, #fff );
	border-radius: 12px;
	box-shadow: 0 1px 4px 0 #E2F0FF, 0 5px 15px 0 #C4D8EF99;
	
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 16px 24px;
		color: var(--rdc-font-color, #173261);
		border-bottom: 1px solid #D1D5DF;
	
		&_title {
			margin: 0 auto;
		}

		& > button {
			padding: 0;
		}
	}

	&__body {
		padding: 16px 24px;
	}

}


