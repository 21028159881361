
.not_scrolled {
    max-width: 780px !important;
    & > .rdc_modal__content {
        overflow: hidden;
    }
}

.modal_wrapper {

    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;

    & > p {
        margin-bottom: 0;
    }

    & > p.headline {
        color: var(--rdc-font-color, #173261);
    }

}
