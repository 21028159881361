
.header {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.header__CreateBtn {
    width: 100%;
    height: 40px;
    & > button {
        height: 40px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        padding: 7px 16px 7px 10px;
        gap: 5px;
        border-radius: 28px;
    }
}

.header__main,
.header__main__part,
.header__toolbar {
    max-height: 32px;
    display: flex;
    align-items: center;
    gap: 8px;

    & > button {
        padding: 0;
    }
}

.header__main,
.header__toolbar {
    justify-content: space-between;
}

button.backBtn {
    padding: 7px 16px 7px 10px;
}