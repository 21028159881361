// Класс для Label'а полей
.field_asterix-after,
.field_asterix {
    position: relative;
}

// Звездочка перед заголовком
.field_asterix-after::before {
    content: '*';
    position: absolute;
    left: -10px;
    color: red;
}

// Звездочка после заголовком
.field_asterix::before {
    content: '*';
    position: absolute;
    right: -10px;
    color: red;
}

.form-label > b {
    font-weight: bold;
}

.card {
    margin: 2rem auto;
}

.form-group > .form-check {
    // display: inline-block;
    margin-left: 1rem;
}

div.mb-3 {
    margin-bottom: 22px !important;
}