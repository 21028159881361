
.modal-dialog-scrollable .modal-content {
    overflow: inherit;
}

.modal-dialog-scrollable .modal-body {
    padding: 0px 34px 20px 24px;
}

.scrollable_container {
    height: 100%;
    overflow-y: scroll;
}
