
.modal_wrapper-redirect {

    & > .rdccs_list-wrapper > .rdccs__header {
        background: #F3F7FF;
        border: 1px solid #D1D5DF;
    }

    & > .rdccs_list-wrapper > .rdccs__header.opened,
    & > .rdccs_list-wrapper > .rdccs__header:hover {
        background-color: white;
        border-color: var( --rdc-main-color, #2196F3);
    }

}