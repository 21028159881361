.help_wrapper {
    position: relative;
    width: 750px;
    min-height: 232px;
    border-radius: 12px;
    background-image: url('./assets/bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: white;
    box-shadow: 0 1px 4px 0 #E2F0FF, 0 5px 15px 0 #C4D8EF99;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 16px 24px;

    &__close {
        position: absolute;
        top: 8px;
        right: 12px;
    }

    & > .login__header {
        margin-bottom: 0;
        & > .login__header__title {
            max-width: 80%;
        }
    }

    & > .login__footer {
        width: 100%;
        padding: 16px 22px;
    }
}

.help_wrapper .controls {
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
