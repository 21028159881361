

.rdc_block {
    display: flex;
    padding: 16px 24px;
    gap: 8px;
    border-radius: 12px;
    background-color: var( --rdc-white-color, white) ;
    box-shadow: 0px 1px 4px 0px #E2F0FF, 0px 5px 15px 0px #C4D8EF99;

    &__title {
        margin: 7px 0;
    }

}

.rdc_block-row {
    flex-direction: row;
}

.rdc_block-col {
    flex-direction: column;
}