
.rdc_control {
	display: flex;
	align-items: center;

	height: 38px;
	padding: 0 15px;

	border: 1px solid #D1D5DF !important;
	border-radius: 6px;

	background: linear-gradient(0deg, #F9FBFF, #F9FBFF),
		linear-gradient(0deg, #D1D5DF, #D1D5DF);

	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: -0.022em;
	text-align: left;

	&.disabled {
		background: var(--secondary-text-color);
	}

	& > .menu-icon {
		font-size: 17px;
		color: var(--main-color);
	}

	& > input.rdc-input_number {
		width: 100%;
		padding: 0 10px;
		color: var(--modal-c-font);
	}

	& > input.rdc-input_number::-webkit-input-placeholder {
		color: var(--main-font-color-disable);
	}

	& > button {
		fill: var(--main-color);
	}

	& > button:hover {
		fill: var(--rdc-error-color);
	}

	& > button:active {
		fill: var(--rdc-error-active-color);
	}

}
