
.pwd_field {
    position: relative;
}

.pwd_eye_btn {
    position: absolute;
    top: 0px;
    right: 5px;
    width: 32px;
    height: 32px;
}
