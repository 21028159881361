
.rdc-stable__wrapper {
    width: 100%;
    overflow: scroll;
}

.rdc-stable {
    min-width: 100%;
    width: max-content;

    &__head {
        position: sticky;
        top: 0;
        z-index: 300;
        
        height: 44px;
        border-bottom: 1px solid #D1D5DF;
        background-color: white;
    }

}

.rdc-stable__head__cell_wrapper {
    display: flex;
    align-items: center;

    & > .head-cell__icon {
        width: 32px;
        height: 100%;
    }

    & > .head-cell__content {
        flex-grow: 1;
        color: var(--rdc-font-color, #173261);
    }

}

.rdc-stable__datarow {
    height: 44px;
    border-bottom: 1px solid #E8F1FF;

    &:hover {
        background: #828F9C1F;
    }

    &.selected {
        background: var( --rdc-blue-color, #2196F3);

        & .data-cell__content {
            color: var( --rdc_white-color, white);
        }

    }
}

.rdc-stable__datacell__wrapper {
    display: flex;
    align-items: center;
    
    & > .data-cell__icon {
        min-width: 32px;
        min-height: 32px;
    }

    & > .data-cell__content {
        flex-grow: 1;
        color: var(--rdc-font-color, #173261);
    }
}

/* W3C standard сейчас только для Firefox */
.rdc-stable__wrapper {
	scrollbar-width: thin;
	scrollbar-color: #D1D5DF white;
}
/* для Chrome/Edge/Safari */
.rdc-stable__wrapper::-webkit-scrollbar {
    width: 6px;
    height: 6px;
	background-color: white;
}
.rdc-stable__wrapper::-webkit-scrollbar-thumb {
	background-color: #D1D5DF;
	border-radius: 4px;
}
.rdc-stable__wrapper::-webkit-scrollbar-thumb:hover {
	background-color: #2196F3;
}
.rdc-stable__wrapper::-webkit-scrollbar-thumb:active {
	background-color: #2167BA;
}