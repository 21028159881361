
.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-window {
    position: relative;
    display: inline-block;
    min-width: 750px;
    max-width: 900px;
	max-height: 80vh;
    padding: 20px;
    vertical-align: middle;
    background-color: #ffffff;
    text-align: left;
}

.close-btn {
    position: relative;
    bottom: 125px;
    right: 30px;
}

.rdc_news-modal {
	border-radius: 12px;
	padding-top: 0;
	max-height: 75vh;

	& > button {
		margin-bottom: 1rem;
	}
}

.rdc_news_wrapper {
	height: 57vh;
	overflow-y: scroll;
	margin-bottom: 1rem;
}

.rdc_news-tb {
	justify-content: flex-end !important;
	& > button {
		margin-right: 1rem;
	}
}

.rdc_new {
	margin: 0 1rem 1rem 0 !important;
}

.rdc_new__header {
    color: var( --rdc-blue-text, #173261) !important;
    font-weight: 500;
}

.rdc_new__context {
    color: var( --rdc-blue-text, #173261);
}
