

.error_wrapper {
    position: absolute;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	backdrop-filter: blur(1px) contrast(35%);
	background: #2167BA4D;
	z-index: 1000;

    display: flex;
    align-items: center;
    justify-content: center;
}

.error_scroll_wr {
    max-height: 90vh;
    max-width: 75%;
    padding: 18px;
    border-radius: 18px;
    color: black;
    background-color: white;
}

.error_block {
    height: 75vh;
    width: 45vw;
    overflow: scroll;

    
}

.error_header {

    display: flex;
    align-items: center;

    margin-bottom: 1rem;

    & > h2 {
        width: 100%;
        font-size: 22px;
        font-weight: bold;
        text-align: center;
    }

    & > button {
        &:hover {
            transform: scale(1.2);
        }

    }

}
