

.rdc_maskinput {

    &__clear {
        position: absolute !important;
        top: 3px;
        right: 3px;
        padding: 0 !important;
        color: aquamarine !important;
    }
}