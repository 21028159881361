
.rdc_datagrid_wrapper {
    position: relative;

    & > button {
        margin-top: 10px;
    }
}

.table-responsive {
    height: 100%;
    overflow: scroll;
    position: relative;
}

table.table  {
    border-collapse: separate;
    border-spacing: 0;
}

.rdct_header {
    position: sticky;
    top: 0;
    margin: 0 0 0 0;
    z-index: 100;
    opacity: 1;
}

.table>:not(caption)>*>* {
    box-shadow: none !important;
}

// Бордер под заголовками
.table > thead > tr:first-child:not(.rdct_header-row) {
    border-color: var(--rdc-gray-disabled, #D1D5DF) !important;
}
// Бордер под фильтрами ( хедером )
.table > thead > tr:last-child {
    border-color: var(--rdc-blue-color, #2196F3) !important;
}

.table tbody tr:not(.nothovered):hover > * {
    background: linear-gradient(0deg, rgba(130, 143, 156, 0.1), rgba(130, 143, 156, 0.1)), linear-gradient(0deg, #F4F8FF, #F4F8FF);
    border-color: #D1D5DF;
}

/* < Empty Panel > */
.rdc_table__emptypanel {
    width: 100%;
    position: absolute;
    top: 85px;
	height: 210px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 10px 0 16px;
}
/* </ Empty Panel > */

.header_cell-wrapper {
    display: flex;
    align-items: center;
    min-height: 32px;
}

.rdct_header,
.rdct_header-row,
.rdct_header-cell,
.rdct_header_filter-row,
.rdct_header_filter-cell {
    height: 44px !important;
    padding: 6px 8px !important;
    background-color: var(--rdc-white-color, white);
}

// .rdct_header,
.rdct_header-row,
.rdct_header-cell,
.rdct_header_filter-row,
.rdct_header_filter-cell {
    position: relative;
}

.header_cell {
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.022em;
    text-align: left;
    color: var(--main-font-color,#173261);
}

.rdct_header_filter-row {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.022em;
    text-align: left;
    color: var(--main-font-color,#173261);
    padding: 0 !important;
}

.rdc_table__cell {
    min-width: 100%;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.022em;
    text-align: left;
    color: var(--main-font-color,#173261);
}

.rdc_table__cell > *,
.header_cell {
    overflow: hidden; 
    white-space: nowrap;
    text-overflow: ellipsis; 
    padding: 6px 0;
}

.header_cell-wrapper {

    & > button {
        margin-right: 0;
        padding: 0;
        & > .icon {
            margin-right: 0;
        }
    }
}

.rdct_header_filter-cell.filtrable:hover {
    background: var(--bgc-hover, #828F9C1A );
}

.rdct_header_filter__wrapper {
    display: flex;
    align-items: center;

    & > span {
        width: 32px;
        color: var(--main-color, #2196F3);
        margin-right: 5px;
    }

    & > input {
        height: 32px;
        width: 100%;
    }
}

.mr-8 {
    margin-right: 10px !important;
}

.rdc_table__cell > span.icon {
    width: 32px;
    color: var(--main-color, #2196F3);
    margin-right: 5px;
}

.rdc_datarow > td {
    max-width: 20vw;
    padding: 0 !important;
}

.resizer {
    position: absolute;
    right: 0;
    top: 0;
    height: 44px;
    width: 5px;
    cursor: col-resize;
    user-select: none;
    touch-action: none;
    background: none;
    border-right: 1px solid var(--rdc-gray-disabled, #D1D5DF);
}
.resizer:active,
.resizer.isResizing {
    background: var(--main-font-color, #173261);
    height: 100vh;
}

.rdct_header-cell.isResizing {
    background-color: #828F9C1A;
	border-color: #173261;
}

.resizer:hover {
    background-color: var(--rdc-gray-disabled, #D1D5DF);
    height: 100vh;
    z-index: 2;
}

.rdc_datarow.row_selected > td {
    background:  linear-gradient(0deg, #F4F8FF, #F4F8FF);
    border-color: #D1D5DF;
}

.icon32 {
    display: inline-block;
    width: 32px;
    height: 32px;
    font-size: 17px;
    vertical-align: -webkit-baseline-middle;
}

thead.rdct_header > tr > th:last-child {
    display: none;
}

.clear_filter {
    position: sticky;
    left: 0;
    height: 44px !important;
    padding: 6px 0 !important;
    z-index: 999;
    & > button {
        padding: 0;
    }
}

.rdct_table-loader {
    width: 64px;
    height: 64px;
    margin-bottom: 16px;
}

tr.row_toolbar > td.more_btn {
    position: absolute;
    padding: 10px !important; 
    transition: all 0.5s;
}
