
.emptypanel_icon {
	width: 170px;
	height: 132px;
    margin: 64px auto;
	margin-bottom: 8px;
	// background: url("./empty.svg");
}

.emptypanel_text {
	font-size: 20px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: -0.022em;
	text-align: center;
	color: #D1D5DF;
}