
.rdc_texthint {
	width: 100%;
	text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.hint[data-hint] {
	position: relative;
}
.hint[data-hint]::after {
	content: attr(data-hint);
	position: absolute;
	z-index: 999999999;
	pointer-events: none;
  
	word-wrap: normal;

	// width: 100%;
	// height: 32px;
	left: 50%;
	bottom: -36px;
  
	scale: 0;
	opacity: 0;
	padding: 8px 14px 6px 14px;
	gap: 10px;
	border-radius: 6px;
  
	color: #FFFFFF;
	background: #828F9CD9;
	font-size: 14px;
	font-weight: 400;
	line-height: 17px;
	letter-spacing: 0px;
	text-align: center;
	transform: translate(-50%, 0);
	transition: opacity, scale 0.4s;

	width: max-content;
	max-width: 300px;
}
.hint.hint-right[data-hint]::after {
	transform: translate( 0%, 0%);
}
.hint.hint-up[data-hint]::after {
	transform: translate( -50%, -150%);
}
.hint.error::after {
	background: #FF3140D9;
}
.hint.success::after {
	background: #2D9E4DD9;
}
.hint[data-hint]:hover::after {
	opacity: 1;
	scale: 1;
}


.hint.hint-nw:after {
	// white-space: nowrap;
	// max-height: 64px;
	transform: translate(-45%, 30%);
}