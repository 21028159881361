

.gar_field {
    width: 100%;
    margin-bottom: 16px;
    position: relative;
}

.gar_label {
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: var(--rdc-blue-text, #173261);
}

.gar_wrapper {
    width: 100%;
    height: 38px;
}

.gar_icon {
    position: absolute;
    left: 8px;
    top: 46px;
    color: var( --rdc-blue-color, #173261 );
}

.gar_input {
    width: 100%;
    height: 38px;
    padding: 4px 32px;
    vertical-align: top;
    border-radius: 6px;
    background-color: var(--rdc-site-bgd, #F3F7FF);
    border: 1px solid var(--rdc-gray-disabled, #D1D5DF);
}

.gar_input.opened {
    border-radius: 6px 6px 0 0;
}

.gar_clearbtn {
    position: absolute;
    right: 4px;
    top: 38px;
}

.gar_spinner {
    position: absolute;
    right: 40px;
    top: 44px;

    width: 18px;
    height: 18px;
    padding: 5px 3px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: conic-gradient(from 90deg at 50% 50%, var(--main-color) 0deg, rgba(33, 150, 243, 0) 360deg);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 3px),#000 0);
    animation: s3 1s infinite linear;
}

@keyframes s3{ 
	100%{transform: rotate(1turn)}
}
