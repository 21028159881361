
@mixin css-arrow($height: 10px, $border: 2px, $color: #efefef, $border-color: #999, $direction: bottom, $outside: true) {
	$border-shift: $border;
	
	@if $border > 0px {
	  $border: round($border * 1.41421356);
	}
	
	position: relative;
	
	&:after,
	&:before {
	  border: solid transparent;
	  content: " ";
	  display: block;
	  height: 0;
	  position: absolute;
	  pointer-events: none;
	  width: 0;
	  
	  @if $direction == top {
		@if $outside {
		  bottom: 100%;
		}
		@else {
		  margin-top: -$border-shift;
		  top: 0;
		}
	  }
	  @if $direction == right {
		@if $outside {
		  left: 100%;
		}
		@else {
		  margin-right: -$border-shift;
		  right: 0;
		}
	  }
	  @if $direction == bottom {
		@if $outside {
		  top: 100%;
		}
		@else {
		  bottom: 0; 
		  margin-bottom: -$border-shift;  
		}
	  }
	  @if $direction == left {
		@if $outside {
		  right: 100%;
		}
		@else {
		  left: 0;
		  margin-left: -$border-shift;
		}
	  }
	}
  
	&:after {
	  border-color: rgba(255, 255, 255, 0);
	  @if $direction == top {
		@if $outside {
		  border-bottom-color: $color;
		}
		@else {
		  border-top-color: $color;
		}
	  }
	  @if $direction == right {
		@if $outside {
		  border-left-color: $color;
		}
		@else {
		  border-right-color: $color;
		}
	  }
	  @if $direction == bottom {
		@if $outside {
		  border-top-color: $color;
		}
		@else {
		  border-bottom-color: $color;
		}
	  }
	  @if $direction == left {
		@if $outside {
		  border-right-color: $color;
		}
		@else {
		  border-left-color: $color;
		}
	  }
	  
	  @if $direction == top or $direction == bottom {
		left: 50%;
		margin-left: -$height;
	  }
	  
	  @if $direction == left or $direction == right {
		top: 50%;
		margin-top: -$height;
	  }
	  
	  border-width: $height;
	}
  
	&:before {
	  border-color: rgba(255, 255, 255, 0);
	  @if $direction == top {
		@if $outside {
		  border-bottom-color: $border-color;
		}
		@else {
		  border-top-color: $border-color;
		}
	  }
	  @if $direction == right {
		@if $outside {
		  border-left-color: $border-color;
		}
		@else {
		  border-right-color: $border-color;
		}
	  }
	  @if $direction == bottom {
		@if $outside {
		  border-top-color: $border-color;
		}
		@else {
		  border-bottom-color: $border-color;
		}
	  }
	  @if $direction == left {
		@if $outside {
		  border-right-color: $border-color;
		}
		@else {
		  border-left-color: $border-color;
		}
	  }
	  
	  @if $direction == top or $direction == bottom {
		left: 50%;
		margin-left: -($height + $border);
	  }
	  
	  @if $direction == left or $direction == right {
		top: 50%;
		margin-top: -($height + $border);
	  }
	  
	  border-width: $height + $border;
	}
}

.flex-row {
	flex-direction: row;
	align-items: center;
}

.flex-col {
	flex-direction: column;
}

.mv-32 {
	margin-top: 32px;
	margin-bottom: 32px;
}

.mr-4 {
	margin-left: 4px;
}

.margin-auto {
	margin: 0 auto;
}

.mb-20 {
	margin-bottom: 20px;
}

.mt-24 {
    margin-top: 24px;
}

.mr-auto {
    margin-right: auto;
}

.mt-auto {
	margin-top: auto;
}

.with-brd {
	border: 3px solid var(--rdc-blue-color, #2196F3);
	border-radius: 16px;
}

.rdc_small_spiner {
    display: inline-block;
    width: 18px;
    height: 18px;
    padding: 5px 3px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: conic-gradient(from 90deg at 50% 50%, var(--rdc-blue-color, #2196F3) 0deg, rgba(33, 150, 243, 0) 360deg);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 3px),#000 0);
    animation: s3 1s infinite linear;
}
.rdc_spiner {
    display: inline-block;
    padding: 5px 3px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: conic-gradient(from 90deg at 50% 50%, var(--rdc-blue-color, #2196F3) 0deg, rgba(33, 150, 243, 0) 360deg);
    -webkit-mask: radial-gradient(farthest-side,#0000 75%,#000 0);
    animation: s3 1s infinite linear;
}
.rdc_spiner-big {
	width: 60px;
	aspect-ratio: 1;
	border-radius: 50%;
	background: conic-gradient(from 90deg at 50% 50%, var(--rdc-blue-color, #2196F3) 0deg, rgba(33, 150, 243, 0) 360deg);
	-webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
	animation: s3 1s infinite linear;
}
@keyframes s3{ 
	100%{transform: rotate(1turn)}
}

.flex-center-4 {
	display: flex;
	align-items: center;
	gap: 4px;
}

.waiting_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 262px;
    padding: 16px 24px 16px 24px;
    border-radius: 12px;
    gap: 16px;
    background: #FFFFFF;
    box-shadow: 0 0 35px 0 #B6CDE9CC, 0 1px 4px 0 #1975BA40;

    & > .rdc_spiner {
        width: 55px;
    }

    & > .text > .centered {
        text-align: center !important;
        color: var(--rdc-font-color, #173261 );
		margin-bottom: 4px;
    }
}
