
.rdc-control {

    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 3px 8px;
    border-radius: 12px 12px 0 0;

    color: var(--rdc-font-color, #173261);
    background-color: var(--rdc-white-color, white);

    font-weight: 400;

    &__ico {
        min-width: 32px;
    }

    &-placeholder {
        background-color: inherit;
        padding: 0;
    }

    &__value {
        width: 100%;
    }

    &__input {
        width: -webkit-fill-available;
    }

    &.rdc-control-field {
        height: 36px;
        padding-left: 16px;
        border-radius: 6px;
        border: 1px solid var( --rdc-gray-disabled, #D1D5DF);
        background: var(--rdc-site-bgd, #F3F7FF);

        &:hover {
            background-color: var( --rdc-white-color, white );
            border-color: var( --rdc-blue-color, #2196F3 );
        }
    }
}