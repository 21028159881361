
$main_color: var(--main-color, #2196F3);
$disabled: var(--rdc-gray-disabled, #D1D5DF);
$hovered: var(--main-bg-c-hover, #828F9C1A);
$shadow-c: var(--main-shadow-color, #2196F34D);
$shadow-c2: var(--main-shadow-color-v2, #2196F326);
$text-c-hovered: var(--main-text-color-hover, #2167BA );

$yellow-c: var(--rdc-yellow, #FFA900);
$yellow-c-active:  var(--rdc-yellow-yellow, #F19100);
$yellow-c-sh:  var(---main-shadow-yellow, #B963004D);
$yellow-c-sh2:  var(--main-shadow-yellow2, #B9630026);

$red-c: var(--rdc-error-color, #FF3140);
$red-c-active: var(--rdc-error-color-active, #CC2733);
$red-c-ch: var(--rdc-error-shadow-color-v2, #CC273326);

$green-c: var(---rdc-green-color, #2D9E4D);
$green-c-active:  var(--rdc-green-a-color, #1D7235);

$error-c: var( --rdc-error-color, #FF3140);
$error-c-active:  var(--rdc-error-active-color, #CC2733);

button.rdc_icon-btn {
    height: 32px;
    padding: 0px 8px 0px 8px;

    display: flex;
    align-items: center;
    gap: 4px;

    background-color: transparent;
    color: $main_color;
    fill: $main_color;

    font-family: Lato;
    font-weight: 400;
    font-size: 16px;

    border: none !important;
    border-radius: 6px;

    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.022em;
    text-align: center;


    & > span {
        margin-right: 5px;
    }

}

button.rdc_icon-btn.btn_block {
    width: 100% !important;
    justify-content: center;
}

button.iconBtn {
    // height: unset !important;
    padding: 0;
}

button.rdc_icon-btn.centered {
    margin-left: auto;
    margin-right: auto;
}

button.rdc_icon-btn.bordered {
    border: 2px solid !important;
}

button.rdc_icon-btn:hover {
    background-color: $hovered;
    color: $main_color;
    fill: $main_color;
}

button.rdc_icon-btn.active,
button.rdc_icon-btn:active {
    background-color: $main_color !important;
    color: var(--rdc-white-color, white);
    fill: var(--rdc-white-color, white);
}

button.rdc_icon-btn.btn_disabled,
button.rdc_icon-btn[disabled = true],
button.rdc_icon-btn.rdc_outline.btn.btn_disabled, .btn:disabled, fieldset:disabled .btn {
    color: $disabled !important;
    fill: $disabled !important;
    pointer-events: none !important;
    background-color: transparent !important;
    opacity: 1 I !important;
}

button.rdc_icon-btn.rdc_outline.blue {
    background-color: transparent;
    color: $main_color;
    fill: $main_color;
}
button.rdc_icon-btn.rdc_outline:hover {
    background-color: $hovered;
    color: $main_color;
    fill: $main_color;
}

button.rdc_icon-btn.rdc_outline.blue.active,
button.rdc_icon-btn.rdc_outline.blue:active {
    background-color: $main_color !important;
    color: var(--rdc-white-color, white);
    fill: var(--rdc-white-color, white);
}
button.rdc_icon-btn.rdc_outline.disabled {
    color: $disabled !important;
    fill: $disabled !important;
}

button.rdc_icon-btn:not(.rdc_outline).blue {
    background-color: $main_color;
    color: var(--rdc-white-color, white);
    fill: var(--rdc-white-color, white);
}
button.rdc_icon-btn:not(.rdc_outline).blue:hover {
    box-shadow: 2px 2px 3px 0px $shadow-c, 6px 6px 10px 0px $shadow-c2;
}
button.rdc_icon-btn:not(.rdc_outline).blue.active,
button.rdc_icon-btn:not(.rdc_outline).blue:active {
    background: $text-c-hovered !important;
    color: var(--rdc-white-color, white);
    fill: var(--rdc-white-color, white);
}

button.rdc_icon-btn:not(.rdc_outline).disabled {
    background: $disabled !important;
}

button.rdc_icon-btn:not(.rdc_outline).yellow {
    background-color: $yellow-c;
    color: var(--rdc-white-color, white);
    fill: var(--rdc-white-color, white);
}
button.rdc_icon-btn:not(.rdc_outline).yellow:hover {
    box-shadow: 2px 2px 3px 0px $yellow-c-sh, 6px 6px 10px 0px $yellow-c-sh2;
}
button.rdc_icon-btn:not(.rdc_outline).yellow.active,
button.rdc_icon-btn:not(.rdc_outline).yellow:active {
    background: $yellow-c-active !important;
    color: var(--rdc-white-color, white);
    fill: var(--rdc-white-color, white);
}

button.red,
button.red:hover {
    fill: var(--main-error-color, #FF3140);
}

button.rdc_icon-btn:not(.rdc_outline).red {
    background-color: $red-c;
    color: white;
    fill: white;
}
button.rdc_icon-btn:not(.rdc_outline).red:hover {
    box-shadow: 2px 2px 3px 0px $red-c-ch, 6px 6px 10px 0px $red-c-ch;
}
button.rdc_icon-btn:not(.rdc_outline).red.active,
button.rdc_icon-btn:not(.rdc_outline).red:active {
    background: $red-c-active !important;
    box-shadow: none;
}

button.rdc_icon-btn.rdc_outline.red {
    background-color: transparent;
    color: $red-c;
    fill: $red-c;
}
button.rdc_icon-btn.rdc_outline.red:hover {
    background-color: $hovered;
    color: $red-c-active;
    fill: $red-c-active !important;
}
button.rdc_icon-btn.rdc_outline.red.active,
button.rdc_icon-btn.rdc_outline.red:active {
    background-color: $red-c !important;
    color: var(--rdc-white-color, white) !important;
    fill: var(--rdc-white-color, white) !important;
}


button.rdc_icon-btn:not(.rdc_outline).green {
    background-color: $green-c;
    color: var(--rdc-white-color, white);
    fill: var(--rdc-white-color, white);
}
button.rdc_icon-btn:not(.rdc_outline).green:hover {
    box-shadow: 2px 2px 3px 0px #248E424D, 6px 6px 16px 0px #248E4226;
}
button.rdc_icon-btn:not(.rdc_outline).green.active,
button.rdc_icon-btn:not(.rdc_outline).green:active {
    background: $green-c-active !important;
    color: var(--rdc-white-color, white);
    fill: var(--rdc-white-color, white);
}

// Error
button.rdc_icon-btn:not(.rdc_outline).error {
    background-color: $error-c;
    color: var(--rdc-white-color, white);
    fill: var(--rdc-white-color, white);
}
button.rdc_icon-btn:not(.rdc_outline).error:hover {
    box-shadow: 2px 2px 3px 0 #CC273326, 6px 6px 10px 0 #CC273326;

}
button.rdc_icon-btn:not(.rdc_outline).error.active,
button.rdc_icon-btn:not(.rdc_outline).error:active {
    background: $error-c-active !important;
    color: var(--rdc-white-color, white);
    fill: var(--rdc-white-color, white);
}
// Error outline
button.rdc_icon-btn.rdc_outline.error {
    background-color: var(--rdc-white-color, white);
    color:  $error-c;
    fill: var(--rdc-white-color, white);
}
button.rdc_icon-btn.rdc_outline.error:hover {
    background-color: $hovered;
}
button.rdc_icon-btn.rdc_outline.error.active,
button.rdc_icon-btn.rdc_outline.error:active {
    background: $error-c-active !important;
    color: var(--rdc-white-color, white);
    fill: var(--rdc-white-color, white);
}
