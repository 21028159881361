
.rdcGroup__list {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.rdcGroup__item {
	display: flex;
	flex-direction: column;
	padding: 16px 24px;
	gap: 16px;
	border-radius: 12px;
	color: var( --rdc-blue-text, #173261);
	background: var( --rdc-white-color, #FFF );
	border: 1px solid var( --rdc-gray-disabled, #D1D5DF);
	transition: all .3s linear;

	&:hover {
		box-shadow: 0 1px 4px 0 #E2F0FF, 0 5px 15px 0 #C4D8EF99;
	}
}

.rdcGroup__item__header {
	cursor: pointer;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	fill: var( --rdc-blue-color, #2196F3 );
	transition: inherit;
	& > .rdcGroup__item__header__expand {
		transition: inherit;
		fill: inherit;
		border-radius: 8px;
		& > svg {
			transition: inherit;
			fill: inherit;
		}
	}
}

.rdcGroup__item:not(.expanded) > .rdcGroup__item__header > .rdcGroup__item__header__expand > svg {
	transform: rotate(180deg);
}

.rdcGroup__item__header:hover > .rdcGroup__item__header__expand {
	fill: var( --rdc-white-color, #fff );
	background-color: var( --rdc-blue-color, #2196F3 );
}

.rdcGroup__item__title {
	display: flex;
	align-items: center;
	margin-bottom: 0;
	max-width: 95%;
	& > div {
		margin-right: 8px;
	}
	& > span {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
}

.rdcGroup__groupItems {
	display: flex;
	flex-direction: column;
}

.rdcGroup__morebtn {
	margin-top: 16px;
}