
.rdc_datarow-cm {
    position: sticky;
    right: 5px;
    width: 32px;
    vertical-align: middle;
    
    color: var( --rdc-blue-color, #2196F3);
    fill: var( --rdc-blue-color, #2196F3);
    background-color: transparent !important;
}

.rdc_datarow-cm > .wrapper {
    width: 32px;
    border-radius: 50%;
}
.rdc_datarow-cm:hover > .wrapper {
    // fill: var(--rdc-white-color, white);
    background-color: var( --rdc-gray-hovering, #2196F3);
}
.rdc_datarow-cm.active > .wrapper,
.rdc_datarow-cm:active > .wrapper {
    color: var(--rdc-white-color, white);
    fill: var(--rdc-white-color, white);
    background-color: var( --rdc-blue-color, #2196F3);
}

.rdc_datarow-cm:not(.custom_tb) > .wrapper {
    opacity: 0;
}

td.rdc_datarow-cm.active > .wrapper ,
.rdc_datarow:hover td.rdc_datarow-cm > .wrapper {
    opacity: 1;
}
