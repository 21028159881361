
$menu-width: var(--menu-width);
$menu-expanded-width: var(--menu-expanded-width);


:root {

    // NEW

    --rdc-white-color: white;
    --rdc-inner-bg-color: #748398;

    --rdc-gray-disabled: #D1D5DF;
    --rdc-gray-hovering: #64748B1F;
    --rdc-gray-hovering-c: #828F9C;
    --rdc-gray-dark: #748398;
    --rdc-gray-stroke: #E4E4E7;

    --rdc-site-bgd: #F3F7FF;
    --rdc-block-bgd: #E8F1FF;

    --rdc-blue-color: #2196F3;
    --rdc-blue-hovering: #1D73D8;
    --rdc-blue-shadow: #2196F380;
    --rdc-blue-text: #173261;

    --rdc-red-light: #FEE2E2;
    --rdc-red-middle: #EF4444;
    --rdc-red-dark: #B91C1C;
    --rdc-red-shadow: #EF444480;
    
    --rdc-red-color: #EF4444;
    --rdc-red-hovering: #B91C1C;

    
    --rdc-yellow: #FF9900;
    --rdc-yellow-active: #D97706;

    --rdc-green-color: #2D9E4D;
    --rdc-green-a-color: #1D7235;


    --main-bc-hover: #E9F7FF;

    --rdc-error-color: #FF3140;
    --rdc-error-active-color: #CC2733;
    --rdc-font-color: #173261;
    --main-color: #2196F3;
    --main-color-v2: #2167BA;
    --main-text-c-disable: #D4D4D4;
    --table-row-c-brd: #E8F1FF;
    --rdc-font-color: #173261;

    --rdc-error-color: #FF3140;
    --rdc-error-color-active: #CC2733;
    --rdc-error-shadow-color: #FF314040;
    
    --main-shadow-yellow: #B963004D;
    --main-shadow-yellow2: #B9630026;
    --menu-font-color: white;
    --menu-color-hover: #ECF5FF40;
    --bgc-hover: #828F9C1A;
    --color-dark-gray: #252C32;
    --modal-c-font: #173261;
    --main-mnu-bc-hover: linear-gradient(0deg, rgba(33, 150, 243, 0.15), rgba(33, 150, 243, 0.15)), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    --main-text-color-hover: #2167BA;
    --main-font-color: #173261;
    --text-helper: #66727F;
    --main-color-shadow: #D8EAFF99;
    --main-box-shadow: 2px 2px 3px 0px rgba(33, 150, 243, 0.3019607843);
    
    --main-bc-modal-overlay: rgba(0, 0, 0, 0.25);
    --main-c-div: #D1E3F5;
    --main-bg-c-hover: #828F9C1A;
    // --main-font-color-disable: #828F9C;
    --main-error-color: #FF3140;
    --main-error-active-color: #CC2733;
    --main-shadow-color: #2196F34D;
    --main-shadow-color-v2: #2196F326;
    --form-radio-color: #2196F3;
    --modal-c-close-btn: #FF3140;
    --modal-bc-hover-close-btn: #FFE0DD;
    --modal-bc-active-close-btn: #CC2733;
    --popup-border-error: #FF3140;
    --popup-shadow-c-error: #CC273326;
    --popup-border-warn: #FFA900;
    --popup-shadow-c-warn: #B9630026;
    --form-bc-control: #F3F7FF;

    //////////////////////////////
    --menu-width: 96px;
    --menu-expanded-width: 220px;
    --menu-expanded-item-width: 200px;
}