

.rdc-cmenu__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    padding: 0 16px;
    fill: var(--rdc-blue-color, #2196F3 );
    font-weight: 400;
    cursor: pointer;
    color: #173261;

    &__content {
        display: flex;
        align-items: center;
        gap: 4px;
        flex-grow: 1;
    }
    
    &-icon {
        color: var(--rdc-blue-color, #2196F3 );
    }
    
    &-text {
        width: 100%;
    }

    &[aria-disabled=false]:hover {
        background: var(--rdc-gray-hovering, #828F9C1F);
    }
    &:active,
    &.active {
        color: var(--rdc-white-color, white) !important;
        background: var(--rdc-blue-color, #2196F3 ) !important;
        fill: var(--rdc-white-color, white) !important;

        & > .rdc-cmenu__item__content > .rdc-cmenu__item-icon {
            color: var(--rdc-white-color, white);
        }
    }

    &[aria-disabled=true] {
        cursor: default;
        color: #D1D5DF;
    }
}
