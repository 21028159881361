

.groupControl {
    display: flex;
    flex-direction: column;

    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: var( --rdc-blue-text, #173261);
}

.groupControlHeader {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
}

.row {
    flex-direction: row;
    gap: 10px;
    & > div {
        flex-grow: 1;
    }
}

// PopUp... Hint

.iconInfo {
    width: 24px;
    height: 24px;
    background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M11%209H13V7H11M12%2020C7.59%2020%204%2016.41%204%2012C4%207.59%207.59%204%2012%204C16.41%204%2020%207.59%2020%2012C20%2016.41%2016.41%2020%2012%2020ZM12%202C10.6868%202%209.38642%202.25866%208.17317%202.7612C6.95991%203.26375%205.85752%204.00035%204.92893%204.92893C3.05357%206.8043%202%209.34784%202%2012C2%2014.6522%203.05357%2017.1957%204.92893%2019.0711C5.85752%2019.9997%206.95991%2020.7362%208.17317%2021.2388C9.38642%2021.7413%2010.6868%2022%2012%2022C14.6522%2022%2017.1957%2020.9464%2019.0711%2019.0711C20.9464%2017.1957%2022%2014.6522%2022%2012C22%2010.6868%2021.7413%209.38642%2021.2388%208.17317C20.7362%206.95991%2019.9997%205.85752%2019.0711%204.92893C18.1425%204.00035%2017.0401%203.26375%2015.8268%202.7612C14.6136%202.25866%2013.3132%202%2012%202ZM11%2017H13V11H11V17Z%22%20fill%3D%22%234BADE1%22%2F%3E%0A%3C%2Fsvg%3E');
}

.PopupBtn {
    position: relative;
    padding: 5px;
    cursor: pointer;
}

.PopUp {
    width: max-content;
    max-width: 390px;
    position: absolute;
    z-index: 1000;
    display: flex;
    opacity: 0;
    border-radius: 12px;
    background-color: var( --rdc-white-color, white);
    top: -20px;
    left: 33px;
    box-shadow: 0 1px 4px #e2f0ff, 0 10px 20px rgba(216, 234, 255, 0.6);
}

.PopUp.WithArrow::before,
.PopUp.WithArrow::after {
  content: '';
  position: absolute;
  left: -20px;
  top: 23px;
  border: 10px solid transparent;
  border-right: 10px solid rgba(75, 173, 225, 0.3);
}
.PopUp.WithArrow::after {
  border-right: 11px solid white;
  top: 23px;
}

.PopUpWrapper {
    padding: 16px 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    & > h6 {
        text-align: center;
        font-weight: 700;
        margin-bottom: 8px;
    }
}

.PopupBtn:hover .PopUp {
    opacity: 1;
}
.PopupBtn:not(:hover) .PopUp {
    display: none !important;
  }