
.rdc-collapseitem {

	height: 32px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	border-radius: 8px;
	overflow: hidden;
	transition: all 0.6s linear;

	&__header {
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 2px;
		border-radius: 6px;
		transition: inherit;

		& > p {
			color: inherit;
		}

		& > button {
			width: 32px;
			height: 32px;
			border-radius: 8px;
			color: var( --rdc-blue-color, #2196F3 );
			transition: inherit;
			& > i {
				transition: inherit;
			}
		}

	}

	&__header:not(.disabled):hover {
		color: var( --rdc-blue-color, #2196F3 );
		& > button {
			color: white;
			background-color: var(--rdc-blue-color, #2196F3 );
		}
	}
	&__header:not(.disabled):active {
		color: var( --rdc-white-color, #fff );
		background-color: var( --rdc-blue-color, #2196F3 );
		& > button {
			color: white;
			background-color: var( --rdc-blue-color, #2196F3 );
		}
	}

}

.rdc-collapseitem.expanded {
	height: auto;
   
	.rdc-collapseitem__header {
		& > button > i {
			transform: rotate(-180deg);
		}
	}
}

.rdc-collapseitem.disabled {
	fill: var(--rdc-gray-dark, #748398);
	color: var(--rdc-gray-dark, #748398);


	& > div.rdc-collapseitem__header {
		cursor: initial;
		& > button {
			cursor: not-allowed;
			color: var(--rdc-gray-dark, #748398);
			background-color: transparent;
		}
	}
}