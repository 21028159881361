

.rdc_modal__body > .body_large {
    color: var( --rdc-blue-text, #173261 );
    
    & > p {
        margin-bottom: 0;
    }
}

.rdc_modal__body.pcenter > .body_large {
    text-align: center;
}

