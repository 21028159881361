
.rdc_cmenu__window {
    position: absolute;
    background-color: var(--rdc-white-color, white);
    z-index: 1000;

    min-width: 360px;
}

.rdc_cmenu__buttons {
    display: flex;
    flex-direction: column;

    padding: 16px 0;
    gap: 4px;
    border-radius: 12px;
    background: #FFFFFF;
    box-shadow: 0 0 24px 0 #B6CDE980, 0 1px 4px 0 #1975BA40;
}

.rdc_cmenu__window_left {
   transform: translate( -100%, 0 );
}