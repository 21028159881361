

.rdc_clearbtn {

    fill: var(--rdc-blue-color, #2196F3);
    transition: all 0.3s;

    & > svg {
        min-width: 32px;
    }

    &[disabled] {
        fill: var( --rdc-gray-dissabled, gray );
    }

    &:not([disabled]):hover {
        fill: var(--rdc-error-color, #FF3140);
        transform: scale(1.2);
    }

    &:not([disabled]):active {
        fill: var(--rdc-error-active-color, #CC2733);
    }

}