
.rdc_blocked__wrapper {
	position: absolute;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	z-index: 101;
	display: flex;
	align-items: center;
	justify-content: center;
}

.rdc_blocked__wrapper.blocked {
	z-index: 200;
	backdrop-filter: blur(1px) contrast(35%);
}

.rdc_modal {
	width: 100%;
	max-width: 700px;
	max-height: 80vh;
	padding: 0;
	border-radius: 12px;
	background-color: var(--rdc-white-color, white);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: var(--main-font-color, #173261);
	box-shadow: 0px 0px 35px 0px #B6CDE9CC, 0px 1px 4px 0px #1975BA40;
	
	&__header {
		width: 100%;
		height: 56px;
		padding: 16px 24px 16px 24px;
		// margin-bottom: 24px;
		gap: 10px;
		border-radius: 12px 12px 0 0;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		background: var(--main-color, #2196F3);
		color: var(--rdc-white-color, white);

		&__title {
			width: 90%;
			text-align: center !important;
			color: var(--rdc-white-color, white) !important;
			margin-bottom: 0;
		}
	}

	&__content {
		width: 100%;
		height: 100%;
		max-height: 80vh;
		padding: 6px 6px 6px 0;
		
		&__icon {
			width: 128px;
			height: 128px;
			margin: 0 auto;
			margin-bottom: 16px;
			fill: var(---rdc-blue-color, #2196F3);
			color: var(--rdc-blue-color, #2196F3);

			&-error {
				fill:  var(--rdc-error-color, #FF3140);
				color: var(--rdc-error-color, #FF3140);
			}
			&-green {
				fill:  var(--rdc-green-color, #2D9E4D);
				color: var(--rdc-green-color, #2D9E4D);
			}
		}

		&__text {
			text-align: center !important;
			margin-bottom: 16px;
			color: var(--main-font-color, #173261) !important;
		}

		&__wrapper {
			width: 100%;
			height: 100%;
			padding: 14px 14px 14px 24px;
			overflow: scroll;
		}
	}

	&__footer {
		width: 100%;
		padding: 16px 24px;
		display: flex;
		justify-content: flex-end;
		border-top: 1px solid #C2E7FF;
		& > button {
			margin-right: 16px;
		}
		& > button:last-child {
			margin-right: 0;
		}
		
	}

	&.error {
		box-shadow: 0px 2px 25px 0px var(--rdc-error-shadow-color, #FF314040), 0px 1px 6px 0px var(--rdc-error-shadow-color, #FF314040);

		.rdc_modal__header {
			background-color: var(---main-error-color, #FF3140);
			color: var(--rdc-white-color, white);

			&__close_btn {
				fill: var( --rdc-white-color, white);
			}
		}

		.rdc_modal__content__icon {
			fill:  var(--rdc-error-color, #FF3140);
			color: var(--rdc-error-color, #FF3140);
		}

	}

	&.light {
		box-shadow: 0 0 35px 0 #B6CDE9CC, 0 1px 4px 0 #1975BA40;
		
		.rdc_modal__header {
			border-bottom: 1px solid #C2E7FF;
			background: #E9F7FF;
			& > .rdc_modal__header__title {
				color: var(--rdc-font-color, #173261) !important;
			}
		}

		.rdc_modal__content__icon {
			fill:  var(--rdc-error-color, #FF3140);
			color: var(--rdc-error-color, #FF3140);
		}

	}

	&.lightblue {
		box-shadow: 0 1px 4px 0 #E2F0FF, 0 5px 15px 0 #C4D8EF99;
		
		& > .rdc_modal__header {
			border-color: var(--White-to-black-Gray-disabled, #D1D5DF);
			background: var(--rdc-white-color, white );
			color: var(--rdc-blue-text, #173261);

			& > .rdc_modal__header__title {
				color: var(--rdc-font-color, #173261) !important;
			}

		}

		& > .rdc_modal__content {
			background: var(--Blue-Blocks-BGD, #E8F1FF);
			border-radius: 12px;

		}

		& > .rdc_modal__footer {
			border-top: 1px solid var(--White-to-black-Gray-disabled, #D1D5DF);
			margin: 0;
			padding: 16px 24px;
		}
	}

	&.green {
		.rdc_modal__content__icon {
			fill:  var(--rdc-green-color, #2D9E4D);
			color: var(--rdc-green-color, #2D9E4D);
		}
	}
}
