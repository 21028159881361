
.rdc_modalheader {
	width: 100%;
	height: 56px;
	padding: 16px 24px 16px 24px;
	gap: 10px;
	border-radius: 12px 12px 0 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	background: var(--main-color, #2196F3);
	color: var(--rdc-white-color, white);
	position: relative;
	&__title {
		width: 90%;
		text-align: center !important;
		color: var(--rdc-white-color, white) !important;
		margin-bottom: 0;
	}
	&__close_btn {
		fill: var(--rdc-white-color, white);
		padding: 0;
		margin: 0;
		background-color: transparent;
		border-radius: 4px;
		transition: all 0.3s;
		&:hover {
			transform: scale(1.2);
		}
		&:active {
			transform: scale(1.2);
			background: var(--modal-bc-active-close-btn);
		}
	}
}

.red > .rdc_modalheader {
	background-color: var( --main-error-color, #FF3140);
}