
.rdc_table__cell {
    display: flex;
    min-height: 44px;
    max-width: 100%;
    padding: 6px 8px;

    & > .rdc_table__cell-content {
        transition: all 1.5s linear;
    }
}

.rdc_datarow.row_expanded > td > .rdc_table__cell > .rdc_table__cell-content {
    height: 100%;
    white-space: break-spaces;
    // word-break: break-all;
    // overflow-wrap: anywhere;
    // hyphens: auto;
}

.rdc_table__cell-icon {
    min-width: 32px;
    height: 32px;
}