
.redoc__contact {

	width: fit-content;
	display: flex;
	align-items: center;
	padding: 7px 16px 7px 10px;	
	gap: 5px;
	border-radius: 6px;

	&-text {
		//styleName: For Button;
		font-size: 14px;
		font-weight: 500;
		line-height: 18px;
		letter-spacing: -0.022em;
		text-align: center;
		color: var(--rdc-blue-color, #2196F3 );
	}

	&-icon {
		border-radius: 5px;
		width: 24px;
	}

	&-icon.icon-green {
		background: #1EB21E;
	}

	&-icon.icon-blue {
		background: #29B6F6;
	}

	&-icon.icon-dblue {
		background: #1E88E5;
	}

	&-icon.icon-orange {
		background: #FFA900;
	}

}

.redoc__contact .redoc__contact-icon > svg {
	transition: all 0.3s;
}
.redoc__contact:hover .redoc__contact-icon > svg {
	transform: rotate(-10deg);
}