
.rdc_modal__body {
	
	display: flex;
	flex-direction: column;
	align-items: center;

	padding: 16px 24px;
	margin-bottom: 0;

	&.pcenter > p {
		text-align: center;
	}

	& > p {
		margin-bottom: 0;
		color: var(--rdc-font-color, #173261);
		&:last-child {
			margin-bottom: 16px;
		}
	}
}
