.helpblock {

	display: flex;
	flex-direction: column;
	word-wrap: break-word;
	border: 1px solid rgba(0, 0, 0, 0.175);
	border-radius: 0.375rem;
	margin-bottom: 1rem;;

	&__body {
		flex: 1 1 auto;
		padding: 1rem;
	}

	&__header {
		cursor: pointer;
		&:hover {
			box-shadow: 18px -10px 22px -10px rgba(34, 60, 80, 0.22);
		}
	}

}