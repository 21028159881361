
.rdct_expand {
    position: sticky;
    left: 0;
}

.rdc_datarow:hover .rdct_expand-cell_wrapper {
    opacity: 1;
}

.rdct_expand-cell_wrapper {
    width: 32px;
	height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
	cursor: pointer;
    fill: var(--main-color, #2196F3 );
    opacity: 0;

    & > button {
        height: 32px;
        border-radius: 50%;
    }

    & > button:hover {
        background: var(--rdc-gray-hovering, #828F9C1F);
        box-shadow: 0px 4px 4px 0px #81A9CC66;
    }

    & > button:active {
        color: var(--rdc-white-color, white);
        fill: var(--rdc-white-color, white);
        background-color: var(--main-color, #2196F3 );
    }

}

.rdct_header_cell__emptybox {
    width: 32px;
    height: 44px;
    border-bottom-width: inherit;
    &-wrapper {
        width: 18px; 
    }
}