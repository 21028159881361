
.rdcc__placeholder {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 12px 12px 0 0;

    color: var(---rdc-blue-text, #173261);
    background-color: inherit;
    font-weight: 400;

    &__ico {
        min-width: 32px;
        fill: var(--rdc-blue-color, #2196F3);
    }

}