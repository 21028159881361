
.rdc_container-block {
	position: absolute;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	backdrop-filter: blur(1px) contrast(35%);
	background: #2167BA4D;
	z-index: 100;
}

.rdc_container-block.fcentered {
	display: flex;
	align-items: center;
	justify-content: center;
}
