
input.form-control {
	height: 38px;
    border-radius: 6px;
    background-color: var(--rdc-site-bgd, #F3F7FF ); 
    border: 1px solid var(--rdc-gray-disabled, #D1D5DF);
	padding-right: calc(1.5em + 0.75rem);
}

input.form-control:read-only {
	background-color: var(--rdc-gray-hovering, #828F9C1F);
}

.rdccs_list-wrapper:hover > div.rdccs__header,
input.form-control:not([readonly]):hover {
	background-color: var(--rdc-white-color, white);
}

input.form-control:not([readonly]):focus {
    background: var(--rdc-white-color, white);
    box-shadow: none !important;
}

.rdcc-radio__list.is-invalid:focus,
.form-control.is-invalid:focus {
    background-color: var(--rdc-white-color, white);
    box-shadow: none !important;
	border: 1px solid var(--rdc-error-color);
}

.rdcc-radio__list.is-invalid:hover,
.rdc-field__placeholder.is-invalid:hover,
.form-control.is-invalid:hover {
	border: 1px solid var(--rdc-error-color);
	background-color: var(--rdc-white-color, white);
}

.rdcc-radio__list.is-invalid,
.rdc-field__placeholder.is-invalid,
.form-control.is-invalid {
	background-image: none;
	background-color: var(--modal-bc-hover-close-btn);
	border-color: transparent;
}

.rdcc-radio__list {
	height: 32px;
	padding-left: 11px;
	border-radius: 6px;
	border: 1px solid transparent;
}

.filesfield_group__load {
	display: block;
	text-align: center;
	cursor: pointer;
}

.filesfield_group__load:hover {
	box-shadow: 18px -10px 22px -10px rgba(34, 60, 80, 0.22);
}

input[type=file][disabled]~.filesfield_group {
	background-color: lightgrey;
}

.form-check {
	margin-bottom: 14px !important;
	padding-top: 34px;
}

.rdc-field__wrapper {
	position: relative;
	min-width: 360px;
	max-width: 390px;

	& > .calendar {
		z-index: 505;
		position: absolute;
		left: 50%;
		bottom: 10px;
	}

}

.rdc-field__placeholder {
	width: 100%;
	height: 38px;
	display: flex;
	align-items: center;
	background-color: #F3F7FF;
    border: 1px solid #D1D5DF;
	
    border-radius: 8px;
	font-size: 16px;
    font-weight: 400;
    line-height: 24px;
	color: var(--rdc-blue-text, #173261);
}

.rdc-field__placeholder:hover {
	background-color: var( --rdc_white-color, white);
}

///////////////////////////////

.rdcc-radio__list {
	display: flex;
	align-items: center;
	column-gap: 28px;
}

.rdcc-radio__item {
	display: flex;
	align-items: center;
	gap: 8px;
	font-size: 16px;
	font-weight: 400;

	& > input[type="radio"] {
		-webkit-appearance: none;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		border: 1px solid var(--rdc-blue-color, #2196F3);
		cursor: pointer;
		position: relative;

		&:disabled {
			border-color: var( --rdc-gray-disabled, #D1D5DF );
		}
		&:disabled:checked:after {
			background: var( --rdc-gray-disabled, #D1D5DF );
		}
		&:checked:after {
			position: absolute;
			top: 3px;
			right: 3px;
			content: "";
			display: inline-block;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background: var(--rdc-blue-color, #2196F3);
		}
	
		&:hover:not(:disabled) {
			border-color: var( --rdc-blue-hover, #1D73D8);
		}
		&:hover:not(:disabled):checked:after {
			background-color: var( --rdc-blue-hover, #1D73D8);
		}
		&:active:not(:disabled) {
			& + label {
				color: var(--rdc-blue-color, #2196F3);
			}
		}
	}

}

//////////////////////////////

.form-group.form-check.inline {
	padding-top: 40px;
}

