
.rdcControl {
    width: 100%;
    min-height: 36px;

    display: flex;
    flex-direction: column;
    gap: 10px;
}

.controls {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.asterix {
    position: relative;
    &::before {
        content: "*";
        position: absolute;
        right: -10px;
        color: var( --rdc-red-middle, #FF3140 );
    }
}

.toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    padding-right: 16px;
}

.files {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    
    max-height: 250px;
    overflow: scroll;
}

.file {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.info {
    display: flex;
    flex-direction: column;

    & > span {
        color: var(--rdc-gray-dark, #748398);
    }
}

.tools {
    margin-left: auto;
}