
.rdc_control {
	position: relative;
	display: flex;
	align-items: center;

	height: 38px;
	padding: 0 8px;

	border-radius: 8px;

	background: var(--rdc-site-bgd, #F3F7FF);
	border: 1px solid var( --rdc-gray-disabled , #D1D5DF);

	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: -0.022em;
	text-align: left;

	&:focus,
	&:hover {
		background-color: var( --rdc-white-color, white);
		border: 1px solid var(--rdc-blue-color, #2196F3);
	}

	&.disabled {
		background: var(--rdc-gray-disabled, #D1D5DF);
	}

	& > .menu-icon {
		font-size: 17px;
		color: var(--rdc-blue-color, #2196F3);
	}

	& > input {
		width: 100%;
		padding: 0 10px;
		color: var(--rdc-blue-text, #173261);
	}

	& > input::-webkit-input-placeholder {
		color: var(--rdc-gray-dark, #748398);
	}

	& > button {
		fill: var(--rdc-blue-color, #2196F3);
		transition: all 0.2s;
	}

	& > button:hover {
		fill: var(---rdc-red-middle, #EF4444);
		transform: scale(1.2);
	}

	& > button:active {
		fill: var(--rdc-red-dark, #B91C1C);
	}

}

.rdc-input_search__hint {
	position: absolute;
    left: 16px;
    top: 40px;
    padding: 8px;
    background-color: #828F9CD9;
	color: var( --rdc-white-color, white );
    border-radius: 8px;
    z-index: 300;
}
