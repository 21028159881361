

.modal_wrapper-callbn {

    & > .rdc_control {
        padding: 3px 16px;
    }

    &:last-child {
        height: 360px;
    }

}

.not_scrolled > .rdc_modal__footer > div.rdc-ctrl-checkbox  {
    margin: 0 auto 0 70px;
}

.not_scrolled.damask {
    height: 230px;
}