@font-face {
	font-family: 'icomoon';
	src:  url('assets/Fonts/icomoon.eot?qnawbj');
	src:  url('assets/Fonts/icomoon.eot?qnawbj#iefix') format('embedded-opentype'),
		url('assets/Fonts/icomoon.ttf?qnawbj') format('truetype'),
		url('assets/Fonts/icomoon.woff?qnawbj') format('woff'),
		url('assets/Fonts/icomoon.svg?qnawbj#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}
	
[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	text-align: center;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/*
   Animation example, for spinners
*/
.animate-spin {
	-moz-animation: spin 2s infinite linear;
	-o-animation: spin 2s infinite linear;
	-webkit-animation: spin 2s infinite linear;
	animation: spin 2s infinite linear;
	display: inline-block;
}
@-moz-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
  
	100% {
	  -moz-transform: rotate(359deg);
	  -o-transform: rotate(359deg);
	  -webkit-transform: rotate(359deg);
	  transform: rotate(359deg);
	}
}
@-webkit-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
  
	100% {
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}
@-o-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
  
	100% {
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}
@-ms-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
  
	100% {
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}
@keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
  
	100% {
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}  

.icon-analytics:before {
	content: "\e942";
  }
  .icon-account-multiple:before {
	content: "\e900";
  }
  .icon-backburger:before {
	content: "\e901";
  }
  .icon-clip:before {
	content: "\e902";
  }
  .icon-copy:before {
	content: "\e903";
  }
  .icon-create-document:before {
	content: "\e904";
  }
  .icon-create_table:before {
	content: "\e905";
  }
  .icon-delete:before {
	content: "\e906";
  }
  .icon-dots:before {
	content: "\e907";
  }
  .icon-download:before {
	content: "\e908";
  }
  .icon-editing:before {
	content: "\e909";
  }
  .icon-export:before {
	content: "\e90a";
  }
  .icon-eye-off:before {
	content: "\e90b";
  }
  .icon-eye:before {
	content: "\e90c";
  }
  .icon-file:before {
	content: "\e90d";
  }
  .icon-image-text:before {
	content: "\e90e";
  }
  .icon-img:before {
	content: "\e90f";
  }
  .icon-import:before {
	content: "\e910";
  }
  .icon-incognito:before {
	content: "\e911";
  }
  .icon-info:before {
	content: "\e912";
  }
  .icon-Link:before {
	content: "\e913";
  }
  .icon-list-status:before {
	content: "\e914";
  }
  .icon-magnify:before {
	content: "\e915";
  }
  .icon-mail:before {
	content: "\e916";
  }
  .icon-main-case:before {
	content: "\e917";
  }
  .icon-menu-right2:before {
	content: "\e918";
  }
  .icon-open:before {
	content: "\e919";
  }
  .icon-phone:before {
	content: "\e91a";
  }
  .icon-Plus:before {
	content: "\e91b";
  }
  .icon-plus_icon:before {
	content: "\e91c";
  }
  .icon-previous-case:before {
	content: "\e91d";
  }
  .icon-print:before {
	content: "\e91e";
  }
  .icon-repeat:before {
	content: "\e91f";
  }
  .icon-save:before {
	content: "\e920";
  }
  .icon-Scan:before {
	content: "\e921";
  }
  .icon-show-file:before {
	content: "\e922";
  }
  .icon-table_export:before {
	content: "\e923";
  }
  .icon-telegram:before {
	content: "\e924";
  }
  .icon-trash:before {
	content: "\e925";
  }
  .icon-tune-vertical:before {
	content: "\e926";
  }
  .icon-wikipedia:before {
	content: "\e927";
  }
  .icon-calendar:before {
	content: "\e928";
  }
  .icon-hanges:before {
	content: "\e929";
  }
  .icon-comment:before {
	content: "\e92a";
  }
  .icon-connection-cases:before {
	content: "\e92b";
  }
  .icon-copy-case:before {
	content: "\e92c";
  }
  .icon-archive-d:before {
	content: "\e92d";
  }
  .icon-applicants:before {
	content: "\e92e";
  }
  .icon-archive:before {
	content: "\e92f";
  }
  .icon-bell:before {
	content: "\e930";
  }
  .icon-cases:before {
	content: "\e931";
  }
  .icon-cr:before {
	content: "\e932";
  }
  .icon-help:before {
	content: "\e933";
  }
  .icon-electronic_queue:before {
	content: "\e934";
  }
  .icon-gmp:before {
	content: "\e935";
  }
  .icon-link:before {
	content: "\e936";
  }
  .icon-menu-right:before {
	content: "\e937";
  }
  .icon-personal_account:before {
	content: "\e938";
  }
  .icon-plus:before {
	content: "\e939";
  }
  .icon-receptionist:before {
	content: "\e93a";
  }
  .icon-registry:before {
	content: "\e93b";
  }
  .icon-reports:before {
	content: "\e93c";
  }
  .icon-search:before {
	content: "\e93d";
  }
  .icon-services:before {
	content: "\e93e";
  }
  .icon-sps:before {
	content: "\e93f";
  }
  .icon-tasks:before {
	content: "\e940";
  }
  .icon-timetable:before {
	content: "\e941";
  }
  
