
.mb-3 {
    position: relative;
}

div.mb-3 > .invalid-feedback {
    position: absolute;
    left: 0;
    width: max-content;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--rdc-error-color);
    margin: 4px;
}

.rdc.invalid-feedback > svg {
    margin-right: 4px;
    vertical-align: text-bottom;
}