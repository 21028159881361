
.rdc_ul_text {
    //styleName: Body;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.022em;
    text-align: left;
    color: var(--rdc-font-color, #173261);

    display: flex;
    flex-direction: column;
    gap: 16px;

    &__item {
        display: flex;
        gap: 16px;
        &__mark {
            margin-top: 10px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: var(--rdc-main-color, #4D83FA);
        }
    }

}