@import-normalize;
@import './reset.css';
@import './fonts.scss';
@import './variable.scss';
@import './Icons.scss';
@import './common.scss';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';

html, body, #root {
	width: 100%;
	height: 100%;
    overflow: hidden;
}

body {
	font-family: 'Lato';
	font-weight: 300;
    color: var( --rdc-blue-color, #173261);
    background-color: var(--rdc-site-bgd, #F3F7FF);
}

@-moz-document url-prefix() {
    /* W3C standard сейчас только для Firefox */
    * {
        scrollbar-width: thin;
        scrollbar-color: #D1D5DF white;
    }
}

/* для Chrome/Edge/Safari */
*::-webkit-scrollbar {
	width: 6px;
    height: 6px;
	background-color: transparent;
}
*::-webkit-scrollbar-thumb {
	background-color: #D1D5DF;
	border-radius: 10px; 
}
*::-webkit-scrollbar-thumb:hover {
	background-color: var(--rdc-blue-color, #2196F3);
}
*::-webkit-scrollbar-thumb:active {
	background-color: var(--rdc-blue-hovering, #2167BA);
}

.main_container {
    background-image: url('./assets/small_bg.png');
}

.logo-part {
    font-weight: 600;
    color: var(--main-color, #2196F3);
}

.modal-content {
	height: 100%;
	border: none;
	// padding-right: 14px;
}

.centered_content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon_btn {
	height: 32px;
	padding: 0 8px;
	border-radius: 6px;
	gap: 4px;
}

.rdc_hidden {
	visibility: hidden;
}

#rdc_tooltip,
#table-tooltip {
    z-index: 999;
    // pointer-events: none;
    opacity: 1;
    width: max-content;
    // height: 32px;
    padding: 8px 14px 6px 14px;
    gap: 10px;
    border-radius: 6px;
    color: #FFFFFF;
    background-color: #828F9CD9;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: center;
    // // transform: translate(-50%, 0);
    transition: all 0.4s;
}

.hint-lb::after {
    left: -75% !important;
}

.Toastify__toast {
    width: 255px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: center;
    padding: 6px 14px;
    gap: 10px;
    border-radius: 8px;
    min-height: unset;
   
    & > div {
        padding: 0;
        & > .Toastify__toast-icon {
            display: none;
        }
    }
}

.Toastify__toast--error {
    background: #FF3140D9 !important;
}
.Toastify__toast.Toastify__toast--success {
	background-color: #2D9E4DD9 !important;
}

.emptyIcon {
	width: 24px;
	height: 100%;
}

.rdc-link {
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: -0.022em;
	text-align: center;

	color: var(--rdc-blue-color, #2196F3);
	&:hover {
		text-decoration: underline;
	}
	&:active {
		color: var(--rdc-blue-hovering, #1D73D8);
	}
	&:disabled {
		color: var(--rdc-gray-disabled, #D1D5DF);
	}
}