

.rdc_content-container {
    // width: 100%;
    height: 100vh;
    display: flex;
}

.rdc_content-container.rdc-fluid {
    margin: -12px 0 0 -12px;
}