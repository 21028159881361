
.RdcGroupItem {
	position: relative;
	width: 100%;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	padding: 6px 8px;
	border-top: 1px solid #E8F1FF;
	transition: all 0.6s linear;

	border-radius: 6px;

	& > span {
		flex-grow: 1;
	}

	&:hover {
		background: var(--rdc-gray-hovering, #828F9C1F);
	} 

	&:active {
		background-color: var( --rdc-blue-color, #2196F3 );

		& > .serviceItem__toolbar > button > svg,
		& > .serviceItem__toolbar_bef > button > svg {
			fill: var( --rdc-white-color, white );
		}
	}

}

.RdcGroupItem__toolbar {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 4px;
	min-width: 105px;

	& > button {
		padding: 0 4px;
		&:last-child {
			opacity: 0;
			padding: 0;
		}
	}
}

.RdcGroupItem__toolbar_bef {
	opacity: 0;
	& > button {
		padding: 0 2px;
		min-width: 32px;
		justify-content: center;
	}
}

.snf {
	min-width: 32px;
}

.RdcGroupItem > .RdcGroupItem__toolbar > button:last-child,
.RdcGroupItem:hover > .RdcGroupItem__toolbar > button:last-child,
.RdcGroupItem:hover > .RdcGroupItem__toolbar_bef {
	opacity: 1;
}

.selected {
	background:  linear-gradient(0deg, #F4F8FF, #F4F8FF);
    border-color: #D1D5DF;
}