
.rdc_submenu {
	position: relative;
	background-color: var(--rdc-white-color, white);
	
	&__wrapper:not(.expanded) {
		width: 20px !important;
		overflow: hidden;
		padding-right: 0;
		padding-left: 25px;
	}

	&__wrapper {
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 16px;
		border: none;
		padding: 16px 16px 22px;
		transition: width 200ms;
		box-shadow: 0px 1px 4px 0px #E2F0FF, 0px 5px 15px 0px #C4D8EF99;

		&.expanded {
			opacity: 1;
			min-width: 380px;
			max-width: 530px;
			width: 100%;
		}
	}

	.expanded &Resize:not(.disabled) {
		position: absolute;
		content: '';
		display: block;
		width: 5px;
		height: 100%;
		right: 0;
		top: 0;
		cursor: e-resize;
		z-index: 0;

		&:hover {
			
			background-color: var(--rdc-gray-disabled, #D1D5DF);
		}

		&.active {
			background: var(--rdc-blue-text, #173261);
		}
	}
	
}
