
.rdc_modal__footer {
	border-top: none;
	padding: 16px 24px 24px 24px;
	& > button {
		padding: 7px 16px;
	}

	&:not(.brd-top) {
		border-top: none;
	}

}

.rdc_modal__footer-topbordered {
	border-top: 1px solid #C2E7FF;
}
