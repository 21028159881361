
/* Контейнер подсказок */
.hint {
	position: relative;
}
  
/* Текст подсказки */
.hint .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: #555;
	color: #fff;
	text-align: center;
	padding: 5px 0;
	border-radius: 6px;
  
	/* Расположите текст подсказки */
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	margin-left: -60px;
  
	/* Исчезают в подсказке */
	opacity: 0;
	transition: opacity 0.3s;
}
  
/* Подсказка стрелка */
.hint .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
}
  
/* Отображение текста подсказки при наведении курсора мыши на контейнер подсказки */
.hint:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}