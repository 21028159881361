
.modalWindow {
	font-size: 20px;
	font-weight: 400;

	// border: 3px solid var(--main-color);
	
	border-radius: 16px;
	min-width: 655px;
	max-height: 90%;
	min-height: 500px;
	background-color: #FFFFFF;
	color: var(--main-color);
	z-index: 100;
	// padding: 0px 34px 20px 24px
}

.certificate_list {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 0;
}

.certificate_list > li {
	margin-bottom: 20px;
}