
.enter-eq__modal {
	cursor: initial;
	width: 200px;
	height: 85px;
	border-radius: 6px;
	// margin-top: 25px;
	color: var(--rdc-main-font-color, #0B1F33);
	background-color: white;
	text-align: center;
	z-index: 101;

	& .modal__header {
		padding: 4px 7px;
		color: white;
		border-radius: 6px 6px 0 0;
		&__title {
			font-size: 12px;
			margin-bottom: 0;
		}
	}

	& .modal__body {
		position: relative;
		padding: 5px 7px;
		font-size: 12px;

		&__ticket {
			font-size: 14px;
			font-weight: 600;
			line-height: 18px;
			letter-spacing: -0.022em;
			margin-bottom: 0;
		}

		&__desc {
			line-height: 14px;
			letter-spacing: 0px;
			white-space: nowrap;
			overflow: hidden;
			margin-bottom: 0;
		}
		&__desc.ta_left {
			text-align: left;
		}

		&:after {
			content: '';
			position: absolute;
			width: 21px;
			height: 100%;
			top: 0;
			right: 0;
			border-radius: 0 8px 8px 0;
			background: linear-gradient(270deg, #FFFFFF 35.71%, rgba(255, 255, 255, 0.19) 100%);
		}
	}

	& .modal__toolbar {
		height: 32px;
		padding: 0 13px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 12px;
		
	}

	&:hover {
		box-shadow: 0px 0px 35px 0px #B6CDE9CC, 0px 1px 4px 0px #1975BA40;
	}

}

.enter-eq__modal.extend {
	height: 110px;
	margin-top: 0;
}
