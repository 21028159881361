
.trober {
    padding: 3.5%;
    border-radius: 16%;
    background-color: var(--main-color);
}

$speed: 2s;

.trober svg path {
    opacity: 0;
}

.trober svg path:first-child {
    animation: arrows $speed step-end infinite;
}

.trober svg path:last-child {
    animation: arrows2 $speed step-end infinite;
}

.trober.sm {
    width: 30px;
    height: 30px;
    padding: 0;
}

.trober.lg {
    width: 50px;
    height: 50px;
    padding: 10px;
}

.trober.xl {
    width: 100px;
    height: 100px;
    padding: 20px;
}

@keyframes arrows {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    75% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes arrows2 {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}