
.login__footer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	padding: 16px 0;

	&-main {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-grow: 1;

		font-size: 12.18px;
		font-weight: 300;
		line-height: 14.61px;
		text-align: left;

	}

	&__logo {
		width: 16px;
		height: 16px;
		background-image: url('../../Themes/assets/small_logo.svg');
		margin-right: 5px;
	}

    & > .logo-part {
		font-weight: 600;
    }

	.login__footer-main > &__copy {
		font-family: Lato;
		font-size: 10px;
		font-weight: 300;
		line-height: 15.88px;
		letter-spacing: -0.022em;
		text-align: left;
		color: var( --rdc-blue-text, #173261);
	}

	&__ccopy {
		font-size: 12px;
		font-weight: 300;
		line-height: 24px;
		letter-spacing: -0.022em;
		text-align: left;
		color: #17326159;
		margin-right: 16px;

		&-ver {
			margin-right: 0;
			letter-spacing: 0em;
			text-align: right;
			align-self: end;
			font-size: 12px;
			font-weight: 300;
			line-height: 24px;
			color: #17326159;
		}

	}

}

.delimiter {
	width: 1px;
	height: 16px;
	background-color: var(--rdc-blue-text, #173261);
	margin: 0 8px;
}