

.rdc_dnbcontainer {

	height: 100%;

	& > .rdc_control {
		margin-bottom: 16px;
	}

	&__wrapper {
		height: 100%;
		&.horizontal {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			width: 100%;
			padding-right: 4px;
		}
		overflow-x: clip;
		overflow-y: scroll;
	}
	&__wrapper.searched {
		height: calc( 100% - 54px );
	}

	&__part {
		width: 100%;
		margin-bottom: 16px;
		&:last-child > ul {
			flex-grow: 1;
		}
	}

	&__title {
		font-size: 18px !important;
		font-weight: 600;
		color: var(--rdc-blue-text, #173261) !important;
		margin-bottom: 12px;
	}

	&__list {
		display: flex;
		flex-direction: column;
		gap: 2px;
		height: 90%;
		min-height: 50px;
		width: 100%;
	}

	&__empty {
		color: var(--rdc-gray-hovering-c, #828F9C);
	}

	&__spiner {
		width: 100%;
    	text-align: center;
	}
}

.rdc_dnbcontainer__item {
	display: flex;
	align-items: center;
	height: 32px;
	border-radius: 6px;
	padding: 0 2px;
	gap: 4px;
	fill: var(--rdc-blue-color, #2196F3);
	margin-bottom: 4px;
	cursor: url("./Assests/cursor.svg"), auto;

	&.main {
		.rdc_dnbcontainer__item__toolbar-std {
			fill: var(--rdc-red-middle, #EF4444);
		}
	}

	&:not(.active,:active):hover {
		color: var(--rdc-blue-color, #2196F3) !important;
		background-color: var(--rdc-gray-hovering, #828F9C1F);
		.rdc_dnbcontainer__item__toolbar,
		.rdc_dnbcontainer__item__toolbar-std {
			opacity: 1;
		}
	}
	
	&:active {
		cursor: url("./Assests/cursor_cl.svg"), auto;	
		color: white;
		background: var(--rdc-blue-color, #2196F3);
		fill: white !important;
		.rdc_dnbcontainer__item__toolbar,
		.rdc_dnbcontainer__item__toolbar-std {
			opacity: 1;
		}
		.rdc_dnbcontainer__item__toolbar.setted svg  {
			fill: var( --rdc-white-color, white);
		}
	}

	&__toolbar-std {
		margin: 0 0 0 auto;
		& > button {
			border-radius: 0 6px 6px 0;
		}
	}

	.filter__placeholder > button {
		border-radius: 6px 0 0 6px;
		&:hover {
			fill: var(--rdc-white-color, white) !important;
			color: var(--rdc-white-color, white) !important;
			background-color: var(--rdc-blue-color, #2196F3) !important;
		}
	}

	&__toolbar-std,
	&__toolbar {
		opacity: 0;
		& > button {
			fill: inherit !important;
			padding: 0;
		}
	}

	&__text {
		width: calc(100% - 70px);
		font-size: 16px !important;
		// color: var(--rdc-blue-text, #173261);
	}

	&__toolbar {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 32px;
		width: 32px;
		fill: inherit;
	}

}

.rdc_dnbcontainer__item__toolbar.active + .rdc_dnbcontainer__item__text {
	color: var(--rdc-blue-color, #2196F3);
}
