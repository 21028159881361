
.footer {
	display: flex;
	align-items: center;
}

.footer__userPart {
	display: flex;
	align-items: center;
	flex-grow: 1;
}

.footer__expandBtn {
	width: 32px;
	transition: all linear 0.3s;
	justify-content: center;
}

.footer__expandBtn.notExpanded {
	position: absolute;
	right: -16px !important;
	bottom: 23px;
	transform: rotate(-180deg);
}



