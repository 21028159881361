

.sortbtn:not(.sortbtn-disable) {
    width: 30px;
    border-radius: 6px;
    margin-right: 2px;
    fill: var( --rdc-blue-color, #2196F3 );
    &:hover {
        background-color: #D1D5DF;
        background-color: var(--rdc-gray-hovering, #828F9C1F );
    }
    &:active {
        fill: var(--rdc-white-color, white);
        background-color: var( --rdc-blue-color, #2196F3);
    }
}

.sortbtn-disable {
    cursor: default !important;
    fill: var(--rdc-gray-disabled, #D1D5DF);
}