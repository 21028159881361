
.contacts {
    max-width: 90%;

    &_text {
       //styleName: Headline xSmall;
        font-family: Lato;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: -0.022em;
        text-align: center;
        color: #173261;
    }

    &_list {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 16px;
        width: 100%;
    }
    
}
