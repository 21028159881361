.block {

	display: flex;
	flex-direction: column;
	padding-right: 4px;
	word-wrap: break-word;
	border: 1px solid rgba(0, 0, 0, 0.175);
	border-radius: 0.375rem;

	&__body {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 1rem;
		overflow-y: scroll;
	}

	&__header {
		padding: 0.5rem;
		margin-bottom: 0;
		color: #000;
		background-color: rgba(0, 0, 0, 0.03);
		border-bottom: 1px solid rgba(0, 0, 0, 0.175);
	}

	&.centered {
		margin: 0 auto;
	}

	&.white {
		padding: 16px 24px;
		gap: 8px;
		background-color: var(--rdc-white-color, white);
		border: none;
		box-shadow: 0 1px 4px 0 #E2F0FF, 0 5px 15px 0 #C4D8EF99;

		& > .block__header {
			padding: 0;
			margin: 0;
			background-color: var(--rdc-white-color, white);
			border: none;
		}

		& > .block__body {
			padding: 0;
		} 
	}

}

div.rdc_block-row {
	& > div.block__body {
		flex-direction: row;
		align-items: center;
	}
}