
.rdc_card {
    display: flex;
    gap: 16px;
    width: 100%;
    margin: 16px 0 16px 16px;
    padding: 24px;
    border-radius: 16px;
    color: var( --rdc-blue-text, #173261);
    background: var(--White-to-black-White, #FFFFFF);
    box-shadow: 0px 1px 4px 0px #E2F0FF, 0px 5px 15px 0px #C4D8EF99;
}

.rdc_card__header {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.rdc_card__header__title {
    margin-bottom: 0;
}

.rdc_card__header__title_centered {
    width: 100%;
    text-align: center !important;
}

.rdc_card__header > div:last-child {
    width: 30%;
    margin-left: auto;
    margin-right: 15px;
}
