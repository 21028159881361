
.button {
    width: max-content;
    min-height: 32px;
    min-width: 32px;
    max-height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 6px;
    padding: 7px 16px;

    & > div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.min,
    &.onlyIcon {
        padding: 0;
    }

    &.min {
        min-width: 26px !important;
        min-height: 26px !important;
    }

    & > svg {
        fill: inherit;
    }

    &.disable {
        background-color: var( --rdc-gray-disabled, #D1D5DF) !important;
        border: none !important;
        box-shadow: none !important;
        cursor: default;
    }

    &.outline.disable {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
        cursor: default;
        color: var( --rdc-gray-disabled, #D1D5DF) !important;
        fill: var( --rdc-gray-disabled, #D1D5DF) !important;
    }

    &.outline {
        &:hover {
            background-color: var( --rdc-gray-stroke, #E4E4E7);
            box-shadow: none;
        }
    }

}

.button {
    position: relative;
    &:not(.outline):hover {
        box-shadow: 0 0 24px 0 #B6CDE980, 0 1px 4px 0 #1975BA40;
    }
    &.bordered {
        border: 2px solid;
    }
    &.block {
        width: 100%;
    }
}

.button_blue {
    color: var( --rdc-white-color, #fff );
    background-color: var( --rdc-blue-color, #2196F3 );
    fill: var( --rdc-white-color, #fff );

    & > .trobber {
        background: conic-gradient(from 90deg at 50% 50%, var( --rdc-white-color, #fff ) 0deg, rgba(33, 150, 243, 0) 360deg);
    }

    &:active {
        background-color: var( --rdc-blue-hovering, #1D73D8);
        // border: 2px solid var( --rdc-blue-hovering, #1D73D8);
    }

    &.outline {
        background-color: transparent;
        color: var( --rdc-blue-color, #2196F3 );
        fill: var( --rdc-blue-color, #2196F3 );

        &:active {
            background-color: var( --rdc-blue-hovering, #1D73D8);
            // border: 2px solid var( --rdc-blue-hovering, #1D73D8);
            color: var( --rdc-white-color, #fff );
            fill: var( --rdc-white-color, #fff );
        }

    }
}

.button_yellow {
    color: var( --rdc-white-color, #fff );
    background-color: var( --rdc-yellow, #FFA900 );
    fill: var( --rdc-white-color, #fff );

    &:active {
        background-color: var( --rdc-yellow-active, #D97706);
        // border: 2px solid var( --rdc-yellow-active, #D97706);
    }

    &.outline {
        background-color: transparent;
        color: var( --rdc-yellow, #FFA900 );
        fill: var( --rdc-yellow, #FFA900 );

        &:active {
            background-color: var( --rdc-yellow-active, #D97706);
            // border: 2px solid var( --rdc-yellow-active, #D97706);
            color: var( --rdc-white-color, #fff );
            fill: var( --rdc-white-color, #fff );
        }

    }
}

.button_green {
    color: var( --rdc-white-color, #fff );
    background-color: var( --rdc-green-color, #2D9E4D );
    fill: var( --rdc-white-color, #fff );

    &:active {
        background-color: var( --rdc-green-a-color, #1D7235);
        // border: 2px solid var( --rdc-green-a-color, #1D7235);
    }

    &.outline {
        background-color: transparent;
        color: var( --rdc-green-color, #2D9E4D );
        fill: var( --rdc-green-color, #2D9E4D );

        &:active {
            background-color: var( --rdc-green-a-color, #1D7235);
            // border: 2px solid var( --rdc-green-a-color, #1D7235);
            color: var( --rdc-white-color, #fff );
            fill: var( --rdc-white-color, #fff );
        }

    }
}

.button_red {
    color: var( --rdc-white-color, #fff );
    background-color: var( --rdc-red-color, #EF4444 );
    fill: var( --rdc-white-color, #fff );

    &:active {
        background-color: var( --rdc-red-hovering, #B91C1C);
        // border: 2px solid var( --rdc-red-hovering, #B91C1C);
    }

    &.outline {
        background-color: transparent;
        color: var( --rdc-red-color, #EF4444 );
        fill: var( --rdc-red-color, #EF4444 );

        &:active {
            background-color: var( --rdc-red-hovering, #B91C1C);
            // border: 2px solid var( --rdc-red-hovering, #B91C1C);
            color: var( --rdc-white-color, #fff );
            fill: var( --rdc-white-color, #fff );
        }

    }
}

.trobber {
    position: absolute;
    top: calc( 50% - 9px );
    left: calc( 50% - 9px );

    display: inline-block;
    width: 18px;
    height: 18px;
    padding: 5px 3px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: conic-gradient(from 90deg at 50% 50%, var( --rdc-blue-color, #2196F3 ) 0deg, rgba(33, 150, 243, 0) 360deg);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 3px),#000 0);
    animation: s3 1s infinite linear;
}

@keyframes s3{ 
	100%{transform: rotate(1turn)}
}
