
.certificate {

    max-width: 565px;

    display: flex;
    align-items: flex-start;
    padding: 20px 0;
    border-radius: 8px;

    line-height: 19.2px;
    text-align: left;
    color: var(--modal-c-font);
    border-color: var(--form-radio-color);

    &_icon {
        position: relative;
        width: 22px;
        height: 22px;
    }
    &_icon > svg {
        max-width: unset;
    }

    &_icon,
    &_cb {
        margin: 0 26px;
        border-color: inherit;
    }

    &_content {
        width: 100%;
    }

    &_subject {
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--main-c-div);
        margin-bottom: 10px;
    }

    &_text {
        margin: 0;
        font-size: 16px;
    }

    &_text > span {
        margin-right: 0.5rem;
    }

    &_text > span:first-child {
        color: #828F9C;
    }
}
.certificate:not(.invalid-cert):hover {
    background: var(--main-bg-c-hover);
}

.certificate:not(.invalid-cert):active {
    background-color: var(--rdc-blue-color, #2196F3);
    color: var(--rdc-white-color, white);
    border-color: var(--rdc-white-color, white);
}

.certificate.invalid-cert {
    color: var(--rdc-gray-disabled, #D1D5DF);
}

.certificate_popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    padding: 16px 24px;

    color: black;
    background-color: #FFFFFF;
    border-radius: 16px;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    &-title {
        font-weight: 600;
        text-wrap: nowrap;
        text-align: center;
        vertical-align: middle;
    }

    &-text {
        text-wrap: nowrap;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0;
    }

}

.cert_hint .tooltip {
    opacity: 1;
}

.cert_hint .tooltip-inner {
    max-width: 60vh;
    border: none;
    background-color: unset;
}

.cert_hint .arrow.tooltip-arrow {
    display: none;
}

///////////////////////////

.certificate_popup.brd-blue {
    border-left: 3px solid var(--main-color);
    box-shadow: 2px 2px 3px 0px var(--main_blue-shadow-color), 6px 6px 10px 0px var(--main-shadow-color-v2);
}

.certificate_popup.brd-red {
    border-left: 3px solid var(--popup-border-error);
    box-shadow: 2px 2px 3px 0px var(--popup-shadow-c-error), 6px 6px 10px 0px var(--popup-shadow-c-error);
}

.certificate_popup.brd-orange {
    border-left: 3px solid var(--popup-border-warn);
    box-shadow: 2px 2px 3px 0px var(--popup-shadow-c-warn), 6px 6px 10px 0px var(--popup-shadow-c-warn);
}

.icon-check-blue {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20id%3D%22Check%22%3E%3Cpath%20id%3D%22Rectangle%201155%20(Stroke)%22%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M2%2012C2%2017.5228%206.47715%2022%2012%2022C17.5228%2022%2022%2017.5228%2022%2012C22%206.47715%2017.5228%202%2012%202C6.47715%202%202%206.47715%202%2012ZM12%200C5.37258%200%200%205.37258%200%2012C0%2018.6274%205.37258%2024%2012%2024C18.6274%2024%2024%2018.6274%2024%2012C24%205.37258%2018.6274%200%2012%200Z%22%20fill%3D%22%230D4CD3%22%2F%3E%3Cpath%20id%3D%22Vector%20(Stroke)%22%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M17.9332%209.6832L12.2763%2015.3401C11.4953%2016.1211%2010.229%2016.1211%209.44792%2015.3401L6.42893%2012.3211L7.84315%2010.9069L10.8621%2013.9258L16.519%208.26898L17.9332%209.6832Z%22%20fill%3D%22%230D4CD3%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
