
.rdc-relationfield {
    max-width: 65vw;
    min-width: 100%;
    height: 38px;
    border-radius: 6px;
    padding: 0;
    background-color: var(--rdc-site-bgd, #F3F7FF);
    border: 1px solid var(--rdc-gray-disabled, #D1D5DF);

    & > .rdcc__header.opened {
        padding: 0;
        border-radius: 6px 6px 0 0;
        border: 1px solid var(--rdc-blue-color, #2196F3);
    }

    &:hover {
        background-color: var( --rdc-white-color, white);
        border-color: var(--rdc-blue-color, #2196F3);
    }

    & > .rdcc__body {
        position: absolute;
        top: 100%;
        max-height: 400px;
    }
    
    &.is-invalid {
        background-color: var(--modal-bc-hover-close-btn);
        border: 1px solid var(--rdc-error-color);
    }
}
