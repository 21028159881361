
.eq_btn {
    border-radius: 6px;
    fill: var( --main-color, #2196F3);
    border: 1px solid transparent;
    &:hover {
        background: #828F9C1F;
        box-shadow: 2px 2px 3px 0px #4BADE14D, 6px 6px 16px 0px #4BADE126;
    }

}

.eq_btn-expand {
    border-radius: 6px;
    border: 1px solid transparent;

    &.active {
        position: relative;
        border-color: var(--main-color,#2196F3);
        box-shadow: 2px 2px 3px 0px #4BADE14D, 6px 6px 16px 0px #4BADE126;
    }

    & > .eq_btn:hover {
        border-radius: 6px 0 0 6px;
        border-color: var(--main-color,#2196F3);
    }

    & > .eq_btn.expand-btn {
        &:hover {
            border-radius: 0 6px 6px 0;
        }
    }
}

.expand_btn_list {
    width: 38px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1px;
    border-radius: 0 0 6px 6px;
    position: absolute;
    z-index: 1999;
    left: 0;
    top: 35px;
    border: 1px solid #2196F3;
    border-top: 0;
    background-color: white;
    padding-top: 3px;
}
