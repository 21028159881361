
.enter-eq {
    margin-top: 76px;
}


.eq_login {
    width: 420px;
    height: 490px;
    position: absolute;
    margin: auto;
    top: 0; left: 0; bottom: 0; right: 0;
    z-index: 101;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__wrapper {
        position: relative;;
        display: flex;
        flex-direction: column;
        padding: 24px 32px 16px 32px;
        border-radius: 12px;
        gap: 16px;
        background: #FFFFFF;
        box-shadow: 0px 1px 4px 0px #E2F0FF, 0px 5px 15px 0px #C4D8EF99;

        &__closebtn {
            position: absolute;
            top: 2px;
            right: 2px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 32px;

        &-part {
            display: flex;
            flex-direction: column;
            gap: 24px;
        }

    }

    &__title {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.022em;
        text-align: center;
        margin-bottom: 0;
        color: #173261;
    }

    &__footer {
        margin: 0 auto;
    }
}

.eq_login__header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
}

.login__header {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin: 0 auto 40px auto;

	&__logo {
		fill: var(--main-color);
	}

	&__title {
		max-width: 60%;
	}
	&__title > span:last-child {
		font-weight: 600;
		color: var(--main-color);
	}

	&__logo {
		margin-right: 1em;
	}
}
