
.modal {
    width: 76vw !important;
    height: 80vh;
    max-width: unset !important;
}

.body {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 24px;
    & > div:last-child {
        flex-grow: 1;
    }
}

.settings.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.7;
}

.settingsFields,
.settings {
    width: 375px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.settingsFields {
    justify-content: flex-start;
    gap: 10px;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.toolbar {
    margin: 0 -2px -10px 0;
    & > p {
        font-size: 14px;
        font-weight: 400;
        color: black;
        margin-right: auto;
    }
}

.version {
    height: 32px;
    padding: 2px 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: var(--rdc-gray-hovering-c, #828F9C);
    margin: 24px 0 -10px -2px;
}
