.simple-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.75);
	overflow: hidden;
	overflow-y: auto;
	transition: opacity 0.3s;
	z-index: 999;
	font-size: 20px;
    font-weight: 400;
	z-index: 100;

	&__wrapper {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		min-height: 100%;
	}

	&__content {
		position: relative;
		margin: 20px;
		width: 100%;
		max-width: 600px;
		border-radius: 20px;
		background-color: var(--rdc-white-color, white);
		// padding: 20px 20px 20px 20px;
		transform: translateY(-50px);
		transition: opacity 0.3s, transform 0.3s;
		border: 2px solid transparent;
	}
}

.simple-modal__body {
	padding: 20px
}

.simple-modal__content.brd-info {
    border-color: var(--main-color);
}

.simple-modal__content.brd-error {
    border-color: var(--rdc-error-color);
}
