
.Info {
	display: flex;
	flex-grow: 1;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	gap: 24px;

	& > svg {
		fill: var( --rdc-blue-color, #2196F3 );
	}

	& > ol > li {
		padding-left: 0.5em;
	}

	&.error {
		& > h3 {
			color: var( --rdc-red-middle, #FF3140);
		}
		& > svg {
			fill: var( --rdc-red-middle, #FF3140);
		}
		& > p {
			text-align: center;
			margin: 2px 48px;
		}
	}

}

.content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	.scanList {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 8px;
		flex-grow: 1;
		max-height: 562px;
		overflow-y: scroll;
		padding-right: 8px;
	}
}

.controls {
	width: 100%;
	height: 34px;

	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
	font-size: 14px;
	font-weight: 400;
	margin: 16px 0;
	border-radius: 6px;
	border: 1px solid var( --rdc-blue-color, #2196F3);

	& > .groupBtn {
		display: flex;
		align-items: center;
		gap: 10px;
	}
}

.page {
	position: relative;
	height: fit-content;
	padding: 4px;
	border-radius: 6px;
	border: 1px solid #D1D5DF;

	& > img {
		width: 100%;
	}

	&:hover > .popup {
		display: flex;
	}
}

.popup {
	display: none;
	align-items: center;
	justify-content: center;
	gap: 15px;
	width: fit-content;
	position: absolute;
	padding: 5px 16px;
	
	left: 50%;
	transform: translate( -50%, 0);

	font-size: 14px;
	font-weight: 400;
	line-height: 16.8px;
	text-align: left;
	color: black;
	background-color: var( --rdc-white-color, white );

	border-radius: 6px;
	border: 1px solid #D1D5DF;
	box-shadow: 0 1px 4px 0 #E2F0FF, 0 5px 15px 0 #C4D8EF99;

	& > .groupBtn {
		display: flex;
		align-items: center;
		gap: 5px;
	}
}

.pageControl {
	top: 14px;
}

.popup.pageIndex {
	bottom: 14px;
}

.iconRotateR {
	transform: scaleX(-1);
}
