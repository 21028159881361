
.rdcc_wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.filter_wrapper {
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	position: relative;
}

.tablefilter-std {
	flex-grow: 1;
}

.filter_value {
	//styleName: Body;
	font-family: Lato;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: -0.022em;
	text-align: left;
	color: var(--main-font-color, #173261);
	flex-grow: 1;
}

.filter__modal {
	position: absolute;
	top: -35px;
	z-index: 9999;
	min-width: 300px;
	width: 100%;
	max-width: 600px;
	max-height: 600px;
	border-radius: 12px;
	border: 1px solid var(--rdc-blue-color, #828F9C);
	box-shadow: 0px 0px 35px 0px #B6CDE9CC, 0px 1px 4px 0px #1975BA40;

	&-header {
		display: flex;
		padding: 3px 8px;
		border-radius: 12px 12px 0 0;
		border-bottom: 1px solid var(--rdc-gray-disabled, #D1D5DF);
		background: var(--form-bc-control, #F3F7FF);

		& > input {
			background-color: transparent;
			border: none;
			color: var(--main-font-color-disable, #828F9C);
			padding: 2px;
			width: 100%;

			& > svg {
				width: 32px;
			}
		}

		& > input:focus {
			// border: none;
			background-color: unset;
			color: var(--main-font-color-disable, #828F9C);
			box-shadow: none;
		}
	}

	&-body {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: calc(100% - 2px);
		padding: 16px 24px 12px 24px;
		border-radius: 0 0 12px 12px;
		border-top: 1px solid var(--rdc-gray-disabled, #D1D5DF);
		background-color: var(--rdc-white-color, white);
		
		& > button:first-child {
			margin-right: 16px;
		}
	}

}

.filter__placeholder {
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-width: 100%;

	& > button {
		padding: 0;
	}

}

.filter__placeholder > svg,
.filter__modal-header > svg {
	min-width: 32px
}

.filter__modal > .calendar,
.filter__modal > .rdcc_list-wrapper > .rdcc__body {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}