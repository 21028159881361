
.inline-icon {
    display: inline-flex;
    align-items: center;
}

.icon-in-line {
    display: inline-block;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 50%;
    margin-right: 1rem;
}

.icon24 {
    width: 24px;
    height: 24px;
}

.icon32 {
    width: 32px;
    height: 32px;
}