

.calendar {
	max-width: 350px;
	max-height: 370px;
	position: absolute;
	z-index: 900;
	left: 50%;
	bottom: 10px;
	display: flex;
	flex: 1 0 0;
	align-self: stretch;
	flex-direction: column;
	align-items: center;
	gap: 16px;

	border-radius: 12px;
	padding: 16px;

	background: var(--rdc-white-color, white);

	/* Blue new style */
	box-shadow: 0px 0px 35px 0px rgba(182, 205, 233, 0.80), 0px 1px 4px 0px rgba(25, 117, 186, 0.25);

	&__controls {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 8px;
	}

}

.navbtns {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4px;

	& button {
		width: 32px;
		padding: 0;
	}

	& button:last-child > svg {
		transform: rotate(0.5turn);
	}
}

.body {
	padding: 0 12px;
}

.header_days {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	gap: 14px;
	margin-bottom: 10px;
	//
	color: var(--text-helper, #66727F);
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}

.header_day {
	display: flex;
	width: 30px;
	height: 30px;
	padding: 10px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	flex-shrink: 0;
}

.days_container {
	display: flex;
	align-items: flex-start;
	gap: 14px;
	flex-wrap: wrap;
}

.date {
	position: relative;
	width: 30px;
	height: 30px;
	border-radius: 20px;
	cursor: pointer;
	border: 2px solid transparent;
	//////
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	z-index: 3;
	color: var( --rdc-blue-text, #173261);
}

.date:not([disabled]):hover {
	border: 2px solid var(--rdc-blue-color, #2196F3);
}

.date[disabled],
.outside {
	color: var(--rdc-gray-disabled, #D1D5DF);
}

.current {
	color: var( --rdc-blue-color, #2196F3);
	background: var( --rdc-site-bgd, #F3F7FF);
}

.changed {
	background: var( --rdc-blue-color, #2196F3);
	color: var(--rdc-white-color, white);
}

.weekend {
	color: var( --rdc-red-middle, #FF3140);
}

.inBetween {
	position: relative;
	z-index: 1;
	&::after {
		display: block;
		content: "";
		position: absolute;
		z-index: -1;
		top: 0px;
		left: -14px;
		width: 60px;
		height: 30px;
		background-color: var( --rdc-site-bgd, #F3F7FF);
	}
}

