
.waiting_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 262px;
    padding: 16px 24px 16px 24px;
    border-radius: 12px;
    gap: 16px;
    background: #FFFFFF;
    box-shadow: 0 0 35px 0 #B6CDE9CC, 0 1px 4px 0 #1975BA40;
    margin: 20% auto;

    
}

.waiting_block__spiner {
    width: 55px;
}

.waiting_block__texts {
    
    & > h5,
    & > h4 {
        text-align: center !important;
        color: var(--rdc-blue-text, #173261 );
    }
}