
.modal__header {    
    position: relative;
    margin-bottom: 8px;
    padding: 16px 24px 8px 24px;
    border-bottom: 1px solid var(--rdc-gray-disabled, #D1D5DF);
}

.modal__header_title {
   //styleName: Headline Small;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.022em;
    margin-bottom: 0;
    color: var(--rdc-blue-text);
    text-align: center;
}

.close_button {
    position: absolute;
    top: 15px;
    right: 24px;
    width: 24px;
    height: 24px;
    padding: 0;
    background-color: transparent;
    border-radius: 4px;
    transition: all 0.3s;
    fill: var(--rdc-blue-color, #2196F3);
}

.close_button_icon {
    vertical-align: sub;
}

.close_button:hover {
    transform: scale(1.2);
    fill: var(--rdc-error-color, #FF3140);
}
.close_button:active {
    fill: var(--rdc-red-dark, #B91C1C);
}
