

.RdcControl {
    width: 100%;
    height: 36px;
    max-height: 40px;

    display: flex;
    align-items: center;

    cursor: pointer;
    border-radius: 6px;
    padding: 6px 10px;
    border: 1px solid var( --rdc-gray-disabled, #D1D5DF);
    background-color: var(--rdc-site-bgd, #F3F7FF);
    fill: var( --rdc-blue-color, #2196F3 );
    color: var( --rdc-blue-text, #173261 );

	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: -0.022em;
	text-align: left;
	color: var(--main-font-color, #173261);

    &.revertHeder {
        border-radius: 0 0 6px 6px;
    }

    &:hover {
        background-color: var( --rdc-white-color, white );
        border-color: var( --rdc-blue-color, #2196F3 );
    }
}

.rtl {
	& > svg {
		order: 5;
	}
	& > .rdccs__header-toolbar {
		order: 1;
	}
}

.opened {
    border-radius: 6px 6px 0 0;
    border-color: var(--main-color, #2196F3);
	& > svg {
		transform: rotate(-180deg);
	}
    border-bottom: 1px solid var(--main-color, #2196F3);
}

.isInvalid {
    border: 1px solid var( --rdc-red-middle, #FF3140 );
    color: var( --rdc-red-middle, #FF3140);
}

/* Стили для кнопки обновления списка сканеров.... */
/* Конпка обновления списка сканеров */
.reloadList {
	display: flex;
	align-items: center;
    justify-content: center;
	margin: -8px 8px 8px 8px;
	padding: 2px 0;
	border-radius: 6px;
	cursor: pointer;
    color: var( --rdc-blue-text, #173261 );
}
.reloadList:not([aria-disabled=true]):active {
	color:  var( --rdc-white-color, white );
	fill: var( --rdc-white-color, white );
	background-color: var( --rdc-blue-color, #2196F3 ) !important;
}
.reloadList[aria-disabled=true] {
	color: var(--rdc-gray-dark, #748398);
}
.reloadList:not([aria-disabled=true]):hover {
	background-color: #828F9C1A;
}
/* Иконка обновления */
.iconReload {
    display: inline-block;
	width: 24px;
	height: 24px;
	background-size: 100% auto;
	background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='inherit' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 9C1.5 12.7275 4.5225 15.75 8.25 15.75C10.0425 15.75 11.76 15.045 13.05 13.8L11.925 12.675C10.9725 13.6875 9.645 14.25 8.25 14.25C3.57 14.25 1.23 8.595 4.5375 5.2875C7.845 1.98 13.5 4.3275 13.5 9H11.25L14.25 12H14.325L17.25 9H15C15 5.2725 11.9775 2.25 8.25 2.25C4.5225 2.25 1.5 5.2725 1.5 9Z' fill='%232196F3'/%3E%3C/svg%3E%0A");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
	margin-right: 4px;
}
/* Спиннер для кнопки обьновления списка */
.iconSpinner {
	width: 17px;
	height: 17px;
	aspect-ratio: 1;
	border-radius: 50%;
	background: conic-gradient(from 90deg at 50% 50%, var( --rdc-blue-color, #2196F3 ) 0deg, rgba(33, 150, 243, 0) 360deg);
	-webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 2px), #000 0);
	animation: s3 1s infinite linear;
	margin-right: 4px;
    @keyframes s3 {
        100% {
            transform: rotate(1turn)
        }
    }
}
/* Иконка успешного обновления ( зеленая галочка ) */
.iconComplete {
    display: inline-block;
	width: 24px;
	height: 24px;
	background-size: 100% auto;
    margin-right: 4px;
	background: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.5 9.5L13.3333 19L9.5 15L8 16.4798L13.3333 22L24 10.9597L22.5 9.5Z' fill='%232D9E4D'/%3E%3C/svg%3E%0A") no-repeat center;
}

/* Стили для списка.... */
.revertBody {
    border-radius: 6px 6px 0 0;
    border-bottom: 0;
    border-top-width: 1px;

    box-shadow: 2px -2px 3px 0 #2196F34D, 6px 0 10px 0 #2196F326;
}
