
.rdc_badge {
    padding: 1px 10px;
    text-align: left;
    border-radius: 8px;
    text-decoration: initial !important;
    width: 100%;
	text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    & > span {
        width: 90%;
    }
}

.rdc_badge:not(.rdc_disabled) {
    color: var(--rdc-font-secondary, #112E4A );
    background-color: #D1D5DF;
}

.rdc_rounded-pill {
    border-radius: 50rem !important;
}

.rdc_badge-red:not(.rdc_disabled) {
    background-color: #FFE0DD !important;
}

.rdc_badge-blue:not(.rdc_disabled) {
    background-color: #C2E7FF !important;
}

.rdc_badge-green:not(.rdc_disabled) {
    background-color: #CFF2D9 !important;
}

.rdc_badge-yellow:not(.rdc_disabled) {
    background-color: #FEF0CC !important;
}

.rdc_badge-gray:not(.rdc_disabled) {
    background-color: #D1D5DF !important;
}

.rdc_badge-white:not(.rdc_disabled) {
    background-color: var( --rdc-white-color, white ) !important;
    border: 1px solid var( --rdc-gray-stroke, #E4E4E7 );
}