
.rdc_submenu {
	position: relative;
	background-color: var(--rdc-white-color, white);
	&__wrapper:not(.expanded) {
		width: 20px !important;
	}

	&__wrapper {
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 16px;
		border: none;
		padding: 16px 16px 22px;
		transition: width 200ms;
		box-shadow: 0px 1px 4px 0px #E2F0FF, 0px 5px 15px 0px #C4D8EF99;

		&.expanded {
			opacity: 1;
			min-width: 380px;
			max-width: 530px;
			width: 100%;
		}
	}

	&__toolbar__top {
		display: flex;
		flex-direction: column;
		gap: 16px;
		
		&-btns {
			width: 100%;
			height: 40px;
			& > button {
				height: 40px;
				display: flex !important;
				align-items: center;
				justify-content: center;
				padding: 7px 16px 7px 10px;
				gap: 5px;
				border-radius: 28px;
			}
		}
	}

	&__toolbar__content {
		flex-grow: 1;
	}

	&__toolbar__bottom {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.expanded &-resize{
		position: absolute;
		content: '';
		display: block;
		width: 5px;
		height: 100%;
		right: 0;
		top: 0;
		cursor: e-resize;
		z-index: 0;

		&:hover {
			background-color: var(--rdc-gray-disabled, #D1D5DF);
		}

		&.active {
			background: var(--rdc-blue-text, #173261);
		}
	}
	
	&__wrapper:not(.expanded) {
		overflow: hidden;
		padding-right: 0;

		padding-left: 25px;

	}

	& .expand_menu-btn {
		width: 32px;
		height: 32px;
		cursor: pointer;
		border: none;
		transition: transform 500ms;
		z-index: 10;
		padding: 0;
		justify-content: center;
	}

	&__wrapper:not(.expanded) .expand_menu-btn {
		position: absolute;
		right: -16px !important;
		bottom: 23px;
	}
	
	& .expand_menu-btn:disabled {
		background: var(--rdc-gray-disabled, #D1D5DF);
	}

	&__wrapper:not(.expanded) .expand_menu-btn > svg { 
		transform: rotate(-180deg);
	}
	
}
