

input.rdcc[type="radio"],
input[type="checkbox"].rdcc {
	width: 18px;
	height: 18px;
	-moz-appearance: none;
	-webkit-appearance: none;
	position: relative;
	float: left;
	margin: 2px;
	border: 2px solid #2196F3;
	border-radius: 3px;
	box-shadow: none;
	background-color: var(--rdc-white-color, white);
	cursor: pointer;

	&:disabled {
		border-color: var(--secondary-text-color, #D1D5DF);
	}

}

input.rdcc[type="radio"]:not(:disabled, :has(+ label)):hover,
input[type="checkbox"].rdcc:not(:disabled, :has(+ label)):hover {
	opacity: 1;
	border-color: var(--rdc-main-color, #2167BA);
}

input[type="checkbox"].rdcc:checked {
	background-repeat: no-repeat;
	background-position: 50%;
	background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22100%22%20height%3D%22100%22%3E%3Cpath%20d%3D%22M100%2017.044l-52.898%2078.956h-1.775l-45.326-39.164%2012.794-16.762%2029.869%2025.744%2040.888-61.044%2016.449%2012.272z%22%20fill%3D%22%23FFF%22%2F%3E%3C%2Fsvg%3E);
	background-size: 80%;
	background-color: #2196F3;
	transition: none;

	&:disabled {
		background-color:  var(--secondary-text-color, #D1D5DF);
	}
}

input[type="checkbox"].rdcc:not(:disabled, :has(+ label)):checked:hover {
	background-color: #2167BA;
}

input.rdcc:not([type=radio]):not([type=checkbox]):not([type=file]),
textarea.rdcc {
	width: 100%;
	height: 36px;
	padding: 9px 10px 9px 16px;
	border: 1px solid #D1D5DF;
	border-radius: 6px;
	vertical-align: middle;
}

input.rdcc:not([type=radio]):not([type=checkbox]):not([type=file]):focus,
textarea.rdcc:focus {
	outline: none;
}

.rdc-ctrl-checkbox {
	width: max-content;
	display: flex;
	align-items: center;
	padding: 4px 6px;
	border-radius: 4px;
	gap: 4px;
	color: var(--rdc-font-color, #173261);

	&.rdcc_block {
		width: 100%;
	}

	&__label {
		//styleName: Body;
		font-family: Lato;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.022em;
		text-align: left;
		width: 100%;
	}

	&.disabled > &__label {
		color: var(--secondary-text-color, #D1D5DF);
	}

	&:not(.disabled):hover {
		background: var(--icon-btn-hover, #828F9C1F);
		
	}

	&:not(.disabled):active {
		color: white;
		background: var(--rdc-main-color, #2196F3);
		& > .rdcc {
			border-color: white;
			background-color: var(--rdc-main-color, #2196F3);
		}
		& > .rdcc:checked {
			border-color: white; //var(--rdc-main-color, #2196F3);
			background-color: white;
			background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22100%22%20height%3D%22100%22%3E%3Cpath%20d%3D%22M100%2017.044l-52.898%2078.956h-1.775l-45.326-39.164%2012.794-16.762%2029.869%2025.744%2040.888-61.044%2016.449%2012.272z%22%20fill%3D%22%232196F3%22%2F%3E%3C%2Fsvg%3E);
		}
	}

}