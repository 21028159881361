

.treelist {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	padding: 0 4px 0 0;
	margin-bottom: 0;

	height: 100%;

	&.searching {
		height: calc( 100% - 54px );
	}

	overflow-y: scroll;

	//styleName: Body large;
	font-family: Lato;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: -0.022em;
	text-align: left;
	color: var( --rdc-blue-text, #173261);

	&::-webkit-scrollbar {
		border-radius: 4px !important;
		width: 6px !important;
		height: 6px !important;
	}
	
	&-wrapper {
		height: 100%;
		& .emptypanel {
			width: 100%;
		}
	}

	&-wrapper > .rdc_control {
		margin-bottom: 16px;
	}
}

.treelist .treelist_node {
	width: 100%;
	fill: var(--rdc-blue-color, #2167BA);
}

.treelist .treelist_node-mnu {
	display: flex;
	align-items: center;
	border-radius: 6px;
	margin-bottom: 8px;
	cursor: pointer;
}

.treelist_node:not(.disable,.active) > .treelist_node-mnu:hover {
	background: var(--rdc-gray-hovering, #828F9C1F);
}

li.treelist_node:not(.disable).active > .treelist_node-mnu,
li.treelist_node:not(.disable) > .treelist_node-mnu:active,
li.treelist_node:not(.disable).active > .treelist_node-mnu > .treelist-expand_icon,
li.treelist_node:not(.disable) > .treelist_node-mnu:active > .treelist-expand_icon,
li.treelist_node:not(.disable).active > .treelist_node-mnu > button > .treelist-expand_icon,
li.treelist_node:not(.disable) > .treelist_node-mnu:active > button > .treelist-expand_icon {
	color: var(--rdc-white-color, white);
	fill: var(--rdc-white-color, white) !important;
	background-color: var(--rdc-blue-color, #2196F3) !important;
	& > button {
		fill: var(--rdc-white-color, white);
	}
}

.treelist .treelist_node.disable > .treelist_node-mnu {
	color: var(--rdc-gray-disabled, #D4D4D4);
}

.treelist .treelist_node .treelist-expand_icon {
	display: inline-block;
	width: 32px;
	height: 32px;
	background-position: center;
	border-radius: 4px;
}

.treelist .treelist_node .treelist-expand_icon[aria-hidden="true"] {
	opacity: 0;
}

.treelist .treelist_node:not(.treelist_node_end).open > .treelist_node-mnu .treelist-expand_icon {
	transform: rotate(90deg);
}


.treelist .treelist_node.tb > .treelist_node-mnu > button {
	opacity: 0;
}

.treelist .treelist_node.tb > .treelist_node-mnu:hover > button {
	opacity: 1;
}


.treelist .treelist_node-title {
	margin-left: 4px;
	text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
} 

.treelist-wrapper.disabled {
	pointer-events: none;
    padding: 10px;
    border-radius: 12px;
    background-color: var(--rdc-gray-disabled, #D4D4D4);
}
