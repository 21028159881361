@font-face {
	font-family: 'Lato-Black';
	src: url('./assets/Fonts/Lato-Black.ttf') format('ttf');
}
@font-face {
    font-family: 'Lato';
    src: url('./assets/Fonts/Lato-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Lato';
    src: url('./assets/Fonts/Lato-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Lato';
    src: url('./assets/Fonts/Lato-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Lato';
    src: url('./assets/Fonts/Lato-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Lato';
    src: url('./assets/Fonts/Lato-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Lato Hairline';
    src: url('./assets/Fonts/Lato-Hairline.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Lato Hairline';
    src: url('./assets/Fonts/Lato-HairlineItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Lato';
    src: url('./assets/Fonts/Lato-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Lato';
    src: url('./assets/Fonts/Lato-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Lato';
    src: url('./assets/Fonts/Lato-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Lato';
    src: url('./assets/Fonts/Lato-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
///////////////////////////////   ///////////////////////////////

.headline-xlarge {
    //styleName: Headline xLarge;
    font-family: Lato;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.022em;
    text-align: left;
    color: var(--rdc-blue-text, #173261 );
}

.headline-large {
    //styleName: Headline Large;
    font-family: Lato;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.022em;
    text-align: left;
    color: var(--rdc-blue-text, #173261 );
}

.headline {
    //styleName: Headline Default;
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.022em;
    text-align: left;
    color: var(--rdc-blue-text, #173261 );
}

.headline-default {
    //styleName: Headline Default;
    font-family: Lato;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.022em;
    text-align: left;
    color: var(--rdc-blue-text, #173261 );
}

.headline-small {
    //styleName: Headline Small;
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.022em;
    text-align: left;
    color: var(--rdc-blue-text, #173261 );
}

.headline-xsmall {
    //styleName: Headline xSmall;
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.022em;
    text-align: left;
    color: var(--rdc-blue-text, #173261 );
}

.body_large {
    //styleName: Body large;
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.022em;
    text-align: left;
    color: var(--rdc-blue-text, #173261 );
}

.body {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.022em;
    text-align: left;

    color: var(--rdc-blue-text, #173261 );
}

.body-small {
    //styleName: Body Small;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.022em;
    text-align: left;
    color: var(--rdc-blue-text, #173261 );
}

.body-xsmall {
   //styleName: Body xSmall;
    font-family: Lato;
    font-size: 12px;
    font-weight: 300;
    line-height: 14px;
    letter-spacing: -0.022em;
    text-align: left;
    color: var(--rdc-blue-text, #173261 );
}

.button-font {
    //styleName: For Button;
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.022em;
    text-align: center;
}
