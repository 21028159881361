
.scroll-container {
	padding: 0 2px 2px 0;

	&__wrapper {
		height: 100%;
		width: 99%;
		display: flex;
		gap: 8px;
		overflow: scroll;
	}
}

.scroll-container.styled {
	padding: 16px 2px 2px 0;
	border-radius: 12px;
	background-color: var( --rdc-white-color, white);
	box-shadow: 0 1px 4px 0 #E2F0FF, 0 5px 15px 0 #C4D8EF99;
}

.scroll-container.styled > .scroll-container__wrapper {
	padding: 0 16px 16px 24px;
	border-radius: 12px;
}