
.rdc-logo__wrapper {
	display: flex;
	align-items: center;
	padding: 16px 0;
	fill: var(--rdc-blue-color, #2196F3);

	& > .rdc-logo__title {
		display: flex;
		margin: 0 8px;
		font-size: 32px;
		vertical-align: middle;
		
		color: var(--rdc-blue-text, #173261);

		& > span:first-child {
			font-weight: 300;
		}
		& > span:last-child {
			font-weight: 600;
			color: var(--rdc-blue-color, #2196F3);
		}
	}

	&-min {
		height: 24px !important;
		padding: 0 !important;
		& > .rdc-logo__img {
			width: 24px !important;
			height: auto !important;
			background-image: none !important;
			margin: 0 !important;
		}
		& > h1.rdc-logo__title {
			flex-direction: row !important;
			margin: 0 5px !important;
			font-size: 18px;
			line-height: 22px;
		}
	}
}
