
.trober-btn {
    height: 32px;
    color: var(--rdc-white-color, white);
    background-color: var(--main-color);
    border-radius: 6px;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;

    gap: 4px;

    border-radius: 6px;
    gap: 3.33px;
    
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.022em;
    text-align: center;
    padding: 7px 18px;

    &.iswaiting {
        padding-top: 0;
        padding-bottom: 0;
    }

}

.trober-btn.is_block {
    width: 100%;
}

.trober-btn:hover:not(:disabled,.iswaiting) {
    box-shadow: 2px 2px 3px 0px #2196F34D, 6px 6px 10px 0px #2196F326;
}

.trober-btn:active:not(:disabled,.iswaiting) {
    background: var(--main-text-color-hover);
}

.trober-btn:disabled {
    background: var(--rdc-gray-disabled, #D1D5DF);
}

// Themes
.trober-btn-green {
    background-color: var(---rdc-green-color, #2D9E4D);
}
.trober-btn-green:hover:not(:disabled,.iswaiting) {
    box-shadow: 2px 2px 3px 0px #248E424D, 6px 6px 16px 0px #248E4226;
}
.trober-btn-green:active:not(:disabled,.iswaiting) {
    background-color: var(--rdc-green-a-color, #1D7235);
}