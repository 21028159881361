

.rdc_invalid-feedback {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    margin: 0;
}

.rdc_invalid-feedback > svg {
    fill: inherit;
    margin-right: 4px;
    vertical-align: text-bottom;
}

.rdc_invalid-feedback--error {
    color: var( --rdc-red-middle, #EF4444 ) !important;
    fill:  var( --rdc-red-middle, #EF4444 );
}

.rdc_invalid-feedback--success {
    color: var( --rdc-green-color, #2D9E4D) !important;
    fill: var( --rdc-green-color, #2D9E4D);
}